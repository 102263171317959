import getToken from '../utils/getToken';
const token = getToken();

const axios = require('axios');
const URL = process.env.REACT_APP_API_URL;

export async function register(schedule) {
  return axios.post(`${URL}v1/schedule/register`, schedule, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}

export async function edit(payload) {
  return axios.patch(`${URL}v1/schedule/editStatus`, payload, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}

export async function reschedule(payload) {
  return axios.patch(`${URL}v1/schedule/reschedule`, payload, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}

export async function getByUser() {
  return axios.get(`${URL}v1/schedule/findByUser`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}

export async function getAllByClientAndUser(clientId) {
  return axios.get(`${URL}v1/schedule/findAllByClientAndUser`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      id: clientId,
    },
  });
}
