import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedLayout from './layouts/ProtectedLayout';
import UnprotectedLayout from './layouts/UnprotectedLayout';
import Login from './pages/Login';
import Calendar from './pages/Calendar';
import { SearchClients, ClientDialog } from './pages/Client';
import Sessions from './pages/Sessions';
import { ForgotPassword, ResetPassword } from './pages/ForgotPassword';

export default function App() {
  return (
    <Routes>
      <Route element={ <UnprotectedLayout /> }>
        <Route path="/" element={<Login />} />
        <Route path="/forgotPassword" element={ <ForgotPassword /> } />
        <Route path="/resetPassword" element={ <ResetPassword /> } />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/client">
          <Route path="search" element={<SearchClients />} />
          <Route path="register" element={
            <ClientDialog actionName="register" />
          } />
        </Route>
        <Route exact path="/sessions/:id" element={<Sessions />} />
      </Route>
      <Route element={ <UnprotectedLayout /> }>
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  );
}
