/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import {
  Box, Grid, Typography, Alert,
} from '@mui/material';

import SessionsCustomTable from
  './components/SessionsCustomTable';

import SessionRecordsListController from
  './components/SessionRecordsListController';

import { useParams } from 'react-router';

import * as clientService from '../../service/ClientService';
// eslint-disable-next-line no-unused-vars
import * as sessionRecordService from '../../service/SessionRecordService';
// eslint-disable-next-line no-unused-vars
import * as scheduleService from '../../service/ScheduleService';

import ClientNameSkeleton from './components/ClientNameSkeleton';
import ListSkeleton from './components/ListSkeleton';
import TableSkeleton from './components/TableSkeleton';

import { useForm, useFieldArray } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { RRule, RRuleSet } from 'rrule';
import { parseJSON, format, parseISO, isEqual } from 'date-fns';

import {
  parseDateStartAndRecurringObjectToRRuleString,
  parseDateToDateStartRule,
  parseRecurringExceptionListToRRule,
} from '../../utils/rruleParser';

import ScheduleStatus from '../../utils/ScheduleStatus';

const miscUtils = require('../../utils/MiscellaneousUtils');

const schema = z
  .object({
    sessionRecords: z.array(
      z.object({
        _id: z.string(),
        description: z.string(),
        evolution: z.string(),
        start: z.date(),
      }),
    ),
  });

function Sessions() {

  // ClientId
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [schedules, setSchedules] = useState(null);

  const [isClientLoading, setIsClientLoading] = useState(true);
  const [isSchedulesLoading, setIsSchedulesLoading] = useState(true);
  const [isSessionRecordsLoading, setIsSessionRecordsLoading] = useState(false);
  const [isSessionRecordsEmpty, setIsSessionRecordsEmpty] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState(Alert.SEVERITY_ERROR);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const {
    resetField,
    getValues,
    setValue,
    control,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const {
    fields: sessionRecordsList,
    update,
  } = useFieldArray({
    control,
    name: 'sessionRecords',
  });

  const showMessage = function (message, severity) {
    setSeverity(severity);
    setAlertMessage(message);
    setOpenAlertMessage(true);
  };

  const getClientData = useCallback((id) => {
    if (!_.isNil(id) && !_.isEmpty(id)) {
      clientService
        .getClientById(id)
        .then((response) => {
          if (!_.isNil(response.data) && !_.isNil(response.data.client)) {
            setClient(response.data.client);
          }
        })
        .catch((err) => {
          showMessage(
            `${err.response.data.message}`,
            miscUtils.SEVERITY_ERROR,
          );
        })
        .finally(() => {
          setIsClientLoading(false);
        });
    }
  }, []);

  const getSchedulesData = useCallback((id) => {
    if (!_.isNil(id) && !_.isEmpty(id)) {
      scheduleService
        .getAllByClientAndUser(id)
        .then((response) => {
          if (!_.isNil(response.data)) {
            const allSchedules = transformRecurringSchedules(response.data);
            setSchedules(allSchedules);
            setIsSchedulesLoading(false);
          }
        })
        .catch((err) => {
          if (!_.isNil(err) && !_.isNil(err.response)) {
            showMessage(
              `${err.response.data.message}`,
              miscUtils.SEVERITY_ERROR,
            );
          }
        })
        .finally(() => {
          setIsSchedulesLoading(false);
        });
    }
  }, []);

  const transformRecurringSchedules = (rawSchedules) => {
    const allSchedules = [];

    for (const schedule of rawSchedules) {
      if (!_.isNil(schedule.recurring)) {

        const scheduleStartDate = schedule.start;
        const recurring = schedule.recurring;
        const recurringException = schedule.recurringException;

        const rruleString = parseDateStartAndRecurringObjectToRRuleString(
          scheduleStartDate, recurring,
        );

        if (!_.isNil(rruleString)) {
          const options = RRuleSet.parseString(rruleString);
          const dtstart = parseDateToDateStartRule(scheduleStartDate);
          options.dtstart = parseISO(dtstart);
          options.tzid = 'America/Sao_Paulo';

          const rule = new RRule(options);

          const rruleSet = new RRuleSet();
          rruleSet.rrule(rule);

          if (!_.isNil(recurringException) && recurringException.length > 0) {
            const exceptions = parseRecurringExceptionListToRRule(
              scheduleStartDate, recurringException,
            );

            exceptions.forEach((date) => {
              rruleSet.exdate(date);
            });
          }

          if (!_.isNil(rule)) {
            const rawDates = rruleSet.all();
            const formattedDates = rawDates.map((d) => new Date(
              d.getUTCFullYear(),
              d.getUTCMonth(),
              d.getUTCDate(),
              d.getHours(),
              d.getMinutes(),
              d.getSeconds(),
            ));

            formattedDates.forEach((date) => {
              let existentRecurringDate = null;

              if (!_.isNil(schedule.savedRecurringSessions)) {
                existentRecurringDate = schedule
                  .savedRecurringSessions
                  .find((el) => isEqual(parseISO(el.start), date));
              }
              
              if (_.isNil(existentRecurringDate)) {
                allSchedules.push({
                  start: date,
                  status: 0,
                  _id: schedule._id,
                });
              } else {
                allSchedules.push({
                  start: parseISO(existentRecurringDate.start),
                  status: existentRecurringDate.status,
                  _id: schedule._id,
                });
              }
            });
          }
        }
      } else {
        allSchedules.push({
          start: parseISO(schedule.start),
          status: schedule.status,
          _id: schedule._id,
        });
      }
    }

    const sortedSchedules = allSchedules
      .sort((a, b) => b.start - a.start);

    return sortedSchedules;
  };

  const getSessionRecordsData = useCallback((ClientID) => {
    setIsSessionRecordsEmpty(false);
    setIsSessionRecordsLoading(true);
    if (!_.isNil(ClientID) && !_.isEmpty(ClientID)) {
      sessionRecordService
        .getByClientAndStatus(ClientID)
        .then((response) => {
          if (!_.isNil(response.data)) {
            const rawSessionRecords = response.data;
            setValue('sessionRecords', rawSessionRecords);
            if (response.data.length === 0) {
              setIsSessionRecordsEmpty(true);
            }
          }
        })
        .catch((err) => {
          showMessage(
            `${err.response.data.message}`,
            miscUtils.SEVERITY_ERROR,
          );
          setTimeout(() => {
            setAlertMessage('');
            setOpenAlertMessage(false);
          }, 5000);
        })
        .finally(() => {
          setTimeout(() => {
            setIsSessionRecordsLoading(false);
          }, 1000);
        });
    }
  }, [setValue]);

  const saveSessionRecord = (sessionId, description, evolution) => {
    sessionRecordService
      .edit({
        id: sessionId,
        description,
        evolution,
      })
      .catch((err) => {
        showMessage(
          `${ err.response.data.message }`,
          miscUtils.SEVERITY_ERROR,
        );
        setTimeout(() => {
          setAlertMessage('');
          setOpenAlertMessage(false);
        }, 5000);
      });
  };

  useEffect(() => {
    getClientData(id);
    getSchedulesData(id);
    getSessionRecordsData(id);
  }, [getClientData, getSchedulesData, getSessionRecordsData, id]);

  const handleEditScheduleStatus = async (scheduleId, status, date) => {
    scheduleService
      .edit({
        id: scheduleId,
        status,
        startDate: date,
      })
      .then(() => {
        if (status === ScheduleStatus.accomplished) {
          sessionRecordService
            .registerByStatusAccomplished({
              evolution: ' ',
              description: ' ',
              start: date,
              ClientId: id,
              scheduleId: scheduleId,
            }).then(() => {
              setTimeout(() => {
                getSessionRecordsData(id);
              }, 500);
            })
            .catch((err) => {
              showMessage(
                `${ err.response.data.message }`,
                miscUtils.SEVERITY_ERROR,
              );
              setTimeout(() => {
                setAlertMessage('');
                setOpenAlertMessage(false);
              }, 5000);
            });
        }
      })
      .catch((err) => {
        showMessage(
          `${ err.response.data.message }`,
          miscUtils.SEVERITY_ERROR,
        );
        setTimeout(() => {
          setAlertMessage('');
          setOpenAlertMessage(false);
        }, 5000);
      });
  };

  const handleSavePostField = async (
    sessionRecordId, index, fieldName, inputValue,
  ) => {
    const updatedField = { ...sessionRecordsList[index] };

    updatedField[fieldName] = inputValue;

    update(index, updatedField);

    const {
      description, evolution,
    } = getValues(`sessionRecords[${ index }]`);

    saveSessionRecord(sessionRecordId, description, evolution);
  };

  const handleResetPostField = (index, fieldName) => {
    resetField(`sessionRecords[${ index }].${ fieldName }`, {
      defaultValue: sessionRecordsList[index][fieldName],
    });
  };

  const renderSessionRecordList = () => {
    if (!isSessionRecordsLoading) {
      if (!_.isNil(sessionRecordsList) &&
        sessionRecordsList.length > 0) {
        return (
          <SessionRecordsListController
            control={ control }
            list={ sessionRecordsList }
            handleSavePostField={ handleSavePostField }
            handleResetPostField={ handleResetPostField }
          />
        );
      } else if (isSessionRecordsEmpty) {
        return (
          <Typography component='p' align='center'>
            Nenhum prontuário encontrado.
          </Typography>
        );
      } else {
        return null;
      }
    } else {
      return (
        <ListSkeleton />
      );
    }
  };

  return (
    <Box
      style={ {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexGrow: 1,
      } }
    >
      <Grid
        container
        spacing={ 1 }
        rowSpacing={ 5 }
        justifyContent='center'
        alignContent='start'
        direction='row'
        sx={ {
          px: 5,
          pb: 10,
          pt: 5,
          maxWidth: '1200px',
        } }
      >
        <Grid item xs={ 12 } pt={ 10 } sx={ {
          display: openAlertMessage ? '' : 'none',
        } }>
          <Alert
            severity={ severity }
            sx={{ display: openAlertMessage ? '' : 'none', flexGrow:1 }}
          >
            { alertMessage }
          </Alert>
        </Grid>
        <Grid item xs={ 12 } pt={ 5 } pb={ 2 }>
          {
            !_.isNil(client) &&
              !isClientLoading
              ? (
                <Typography variant="h4" fontWeight={ 500 }>
                  Sessões de {
                    client.name + ' ' + client.surname
                  }: {
                    client.cellphone.length > 0 &&
                    client.cellphone.slice(0, 4) +
                    ' ' +
                    client.cellphone.slice(4, client.cellphone.length)
                  }
                </Typography>
              )
              : (
                <ClientNameSkeleton />
              )
          }
        </Grid>
        <Grid
          item
          xs={ 12 }
          md={ 5 }
          lg={ 4 }
        >
          {
            !_.isNil(schedules) &&
              !isSchedulesLoading ?
              (
                <SessionsCustomTable
                  fetchSessionRecords={ getSessionRecordsData }
                  schedules={ schedules }
                  handleEditScheduleStatus={ handleEditScheduleStatus }
                />
              )
              :
              (
                <TableSkeleton />
              )
          }
        </Grid>
        <Grid
          item
          xs={ 12 }
          md={ 7 }
          lg={ 8 }
        >
          { renderSessionRecordList() }
        </Grid>
      </Grid>
    </Box>
  );
}

export default Sessions;