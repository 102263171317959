/*
 * https://countrycode.org
 * https://history.state.gov/countries/all
 */

const getCountries = () => [
  // A
  { isocode: 'AFG', code: '93', name: 'Afghanistan', namePtBR: 'Afeganistão' },
  { isocode: 'ALB', code: '335', name: 'Albania', namePtBR: 'Albânia' },
  { isocode: 'DZA', code: '213', name: 'Algeria', namePtBR: 'Argélia' },
  {
    isocode: 'ASM', code: '1-684', name: 'American Samoa',
    namePtBR: 'Samoa Americana',
  },
  { isocode: 'AND', code: '376', name: 'Andorra', namePtBR: 'Andorra' },
  { isocode: 'AGO', code: '244', name: 'Angola', namePtBR: 'Angola' },
  { isocode: 'AIA', code: '1-264', name: 'Anguilla', namePtBR: 'Anguilla' },
  { isocode: 'ATA', code: '672', name: 'Antarctica', namePtBR: 'Antártica' },
  {
    isocode: 'ATG', code: '1-268', name: 'Antigua and Barbuda',
    namePtBR: 'Antígua e Barbuda',
  },
  { isocode: 'ARG', code: '54', name: 'Argentina', namePtBR: 'Argentina' },
  { isocode: 'ARM', code: '374', name: 'Armenia', namePtBR: 'Armênia' },
  { isocode: 'ABW', code: '297', name: 'Aruba', namePtBR: 'Aruba' },
  { isocode: 'AUS', code: '61', name: 'Australia', namePtBR: 'Austrália' },
  { isocode: 'AUT', code: '43', name: 'Austria', namePtBR: 'Áustria' },
  { isocode: 'AZE', code: '994', name: 'Azerbaijan', namePtBR: 'Azerbaijão' },
  // B
  { isocode: 'BHS', code: '1-242', name: 'Bahamas', namePtBR: 'Bahamas' },
  { isocode: 'BHR', code: '973', name: 'Bahrain', namePtBR: 'Bahrein' },
  { isocode: 'BGD', code: '880', name: 'Bangladesh', namePtBR: 'Bangladesh' },
  { isocode: 'BRB', code: '1-246', name: 'Barbados', namePtBR: 'Barbados' },
  { isocode: 'BLR', code: '375', name: 'Belarus', namePtBR: 'Bielorrússia' },
  { isocode: 'BEL', code: '32', name: 'Belgium', namePtBR: 'Bélgica' },
  { isocode: 'BLZ', code: '501', name: 'Belize', namePtBR: 'Belize' },
  { isocode: 'BEN', code: '229', name: 'Benin', namePtBR: 'Benin' },
  { isocode: 'BMU', code: '1-441', name: 'Bermuda', namePtBR: 'Bermudas' },
  { isocode: 'BTN', code: '975', name: 'Bhutan', namePtBR: 'Butão' },
  { isocode: 'BOL', code: '591', name: 'Bolivia', namePtBR: 'Bolívia' },
  {
    isocode: 'BIH', code: '387', name: 'Bosnia and Herzegovina',
    namePtBR: 'Bósnia e Herzegovina',
  },
  { isocode: 'BWA', code: '267', name: 'Botswana', namePtBR: 'Botswana' },
  { isocode: 'BRA', code: '55', name: 'Brazil', namePtBR: 'Brasil' },
  {
    isocode: 'IOT', code: '246', name: 'British Indian Ocean Territory',
    namePtBR: 'Território Britânico do Oceano Índico',
  },
  {
    isocode: 'VGB', code: '1-284', name: 'British Virgin Islands',
    namePtBR: 'Ilhas Virgens Britânicas',
  },
  { isocode: 'BRN', code: '673', name: 'Brunei', namePtBR: 'Brunei' },
  { isocode: 'BGR', code: '359', name: 'Bulgaria', namePtBR: 'Bulgária' },
  {
    isocode: 'BFA', code: '226', name: 'Burkina Faso',
    namePtBR: 'Burkina Faso',
  },
  { isocode: 'BDI', code: '257', name: 'Burundi', namePtBR: 'Burundi' },
  // C
  { isocode: 'KHM', code: '855', name: 'Cambodia', namePtBR: 'Camboja' },
  { isocode: 'CMR', code: '237', name: 'Cameroon', namePtBR: 'Camarões' },
  { isocode: 'CAN', code: '1', name: 'Canada', namePtBR: 'Canadá' },
  { isocode: 'CPV', code: '238', name: 'Cape Verde', namePtBR: 'Cabo Verde' },
  {
    isocode: 'CYM', code: '1-345', name: 'Cayman Islands',
    namePtBR: 'Ilhas Cayman',
  },
  {
    isocode: 'CAF', code: '236', name: 'Central African Republic',
    namePtBR: 'República Centro-Africana',
  },
  { isocode: 'TCD', code: '235', name: 'Chad', namePtBR: 'Chade' },
  { isocode: 'CHL', code: '56', name: 'Chile', namePtBR: 'Chile' },
  { isocode: 'CHN', code: '86', name: 'China', namePtBR: 'China' },
  {
    isocode: 'CXR', code: '61', name: 'Christmas Island',
    namePtBR: 'Ilha de Natal',
  },
  {
    isocode: 'CCK', code: '61', name: 'Cocos Islands',
    namePtBR: 'Ilhas Cocos',
  },
  { isocode: 'COL', code: '57', name: 'Colombia', namePtBR: 'Colômbia' },
  { isocode: 'COM', code: '269', name: 'Comoros', namePtBR: 'Comores' },
  { isocode: 'COK', code: '682', name: 'Cook Islands', namePtBR: 'Ilhas Cook' },
  { isocode: 'CRI', code: '506', name: 'Costa Rica', namePtBR: 'Costa Rica' },
  { isocode: 'HRV', code: '385', name: 'Croatia', namePtBR: 'Croácia' },
  { isocode: 'CUB', code: '53', name: 'Cuba', namePtBR: 'Cuba' },
  { isocode: 'CUW', code: '599', name: 'Curacao', namePtBR: 'Curaçao' },
  { isocode: 'CYP', code: '357', name: 'Cyprus', namePtBR: 'Chipre' },
  {
    isocode: 'CZE', code: '420', name: 'Czech Republic',
    namePtBR: 'República Tcheca',
  },
  // D
  {
    isocode: 'COD', code: '243', name: 'Democratic Republic of the Congo',
    namePtBR: 'República Democrática do Congo',
  },
  { isocode: 'DNK', code: '45', name: 'Denmark', namePtBR: 'Dinamarca' },
  { isocode: 'DJI', code: '253', name: 'Djibouti', namePtBR: 'Djibouti' },
  { isocode: 'DMA', code: '1-767', name: 'Dominica', namePtBR: 'Dominica' },
  {
    isocode: 'DOM', code: '1-809', name: 'Dominican Republic',
    namePtBR: 'República Dominicana',
  },
  // E
  { isocode: 'TLS', code: '670', name: 'East Timor', namePtBR: 'Timor-Leste' },
  { isocode: 'ECU', code: '593', name: 'Ecuador', namePtBR: 'Equador' },
  { isocode: 'EGY', code: '20', name: 'Egypt', namePtBR: 'Egito' },
  { isocode: 'SLV', code: '503', name: 'El Salvador', namePtBR: 'El Salvador' },
  {
    isocode: 'GNQ', code: '240', name: 'Equatorial Guinea',
    namePtBR: 'Guiné Equatorial',
  },
  { isocode: 'ERI', code: '291', name: 'Eritrea', namePtBR: 'Eritreia' },
  { isocode: 'EST', code: '372', name: 'Estonia', namePtBR: 'Estônia' },
  { isocode: 'ETH', code: '251', name: 'Ethiopia', namePtBR: 'Etiópia' },
  // F
  {
    isocode: 'FLK', code: '500', name: 'Falkland Islands',
    namePtBR: 'Ilhas Falkland',
  },
  {
    isocode: 'FRO', code: '298', name: 'Faroe Islands',
    namePtBR: 'Ilhas Faroé',
  },
  { isocode: 'FJI', code: '679', name: 'Fiji', namePtBR: 'Fiji' },
  { isocode: 'FIN', code: '358', name: 'Finland', namePtBR: 'Finlândia' },
  { isocode: 'FRA', code: '33', name: 'France', namePtBR: 'França' },
  {
    isocode: 'PYF', code: '689', name: 'French Polynesia',
    namePtBR: 'Polinésia Francesa',
  },
  // G
  { isocode: 'GAB', code: '241', name: 'Gabon', namePtBR: 'Gabão' },
  { isocode: 'GMB', code: '220', name: 'Gambia', namePtBR: 'Gâmbia' },
  { isocode: 'GEO', code: '995', name: 'Georgia', namePtBR: 'Georgia' },
  { isocode: 'DEU', code: '49', name: 'Germany', namePtBR: 'Alemanha' },
  { isocode: 'GHA', code: '233', name: 'Ghana', namePtBR: 'Gana' },
  { isocode: 'GIB', code: '350', name: 'Gibraltar', namePtBR: 'Gibraltar' },
  { isocode: 'GRC', code: '30', name: 'Greece', namePtBR: 'Grécia' },
  { isocode: 'GRL', code: '299', name: 'Greenland', namePtBR: 'Groenlândia' },
  { isocode: 'GRD', code: '1-473', name: 'Grenada', namePtBR: 'Granada' },
  { isocode: 'GUM', code: '1-671', name: 'Guam', namePtBR: 'Guam' },
  { isocode: 'GTM', code: '502', name: 'Guatemala', namePtBR: 'Guatemala' },
  { isocode: 'GGY', code: '44-1481', name: 'Guernsey', namePtBR: 'Guernsey' },
  { isocode: 'GIN', code: '224', name: 'Guinea', namePtBR: 'Guiné' },
  {
    isocode: 'GNB', code: '245', name: 'Guinea-Bissau',
    namePtBR: 'Guiné-Bissau',
  },
  { isocode: 'GUY', code: '592', name: 'Guyana', namePtBR: 'Guiana' },
  // H
  { isocode: 'HTI', code: '509', name: 'Haiti', namePtBR: 'Haiti' },
  { isocode: 'HND', code: '504', name: 'Honduras', namePtBR: 'Honduras' },
  { isocode: 'HKG', code: '852', name: 'Hong Kong', namePtBR: 'Hong Kong' },
  { isocode: 'HUN', code: '36', name: 'Hungary', namePtBR: 'Hungria' },
  // I
  { isocode: 'ISL', code: '354', name: 'Iceland', namePtBR: 'Islândia' },
  { isocode: 'IND', code: '91', name: 'India', namePtBR: 'Índia' },
  { isocode: 'IDN', code: '62', name: 'Indonesia', namePtBR: 'Indonésia' },
  { isocode: 'IRN', code: '98', name: 'Iran', namePtBR: 'Irã' },
  { isocode: 'IRQ', code: '964', name: 'Iraq', namePtBR: 'Iraque' },
  { isocode: 'IRL', code: '353', name: 'Ireland', namePtBR: 'Irlanda' },
  {
    isocode: 'IMN', code: '44-1624', name: 'Isle of Man',
    namePtBR: 'Ilha de Man',
  },
  { isocode: 'ISR', code: '972', name: 'Israel', namePtBR: 'Israel' },
  { isocode: 'ITA', code: '39', name: 'Italy', namePtBR: 'Itália' },
  {
    isocode: 'CIV', code: '225', name: 'Ivory Coast',
    namePtBR: 'Costa do Marfim',
  },
  // J
  { isocode: 'JAM', code: '1-876', name: 'Jamaica', namePtBR: 'Jamaica' },
  { isocode: 'JPN', code: '81', name: 'Japan', namePtBR: 'Japão' },
  { isocode: 'JEY', code: '44-1534', name: 'Jersey', namePtBR: 'Jersey' },
  { isocode: 'JOR', code: '962', name: 'Jordan', namePtBR: 'Jordânia' },
  // K
  { isocode: 'KAZ', code: '7', name: 'Kazakhstan', namePtBR: 'Cazaquistão' },
  { isocode: 'KEN', code: '254', name: 'Kenya', namePtBR: 'Quênia' },
  { isocode: 'KIR', code: '686', name: 'Kiribati', namePtBR: 'Kiribati' },
  { isocode: 'XKX', code: '383', name: 'Kosovo', namePtBR: 'Kosovo' },
  { isocode: 'KWT', code: '965', name: 'Kuwait', namePtBR: 'Kuwait' },
  { isocode: 'KGZ', code: '996', name: 'Kyrgyzstan', namePtBR: 'Quirguistão' },
  // L
  { isocode: 'LAO', code: '856', name: 'Laos', namePtBR: 'Laos' },
  { isocode: 'LVA', code: '371', name: 'Latvia', namePtBR: 'Letônia' },
  { isocode: 'LBN', code: '961', name: 'Lebanon', namePtBR: 'Líbano' },
  { isocode: 'LSO', code: '266', name: 'Lesotho', namePtBR: 'Lesoto' },
  { isocode: 'LBR', code: '231', name: 'Liberia', namePtBR: 'Libéria' },
  { isocode: 'LBY', code: '218', name: 'Libya', namePtBR: 'Líbia' },
  {
    isocode: 'LIE', code: '423', name: 'Liechtenstein',
    namePtBR: 'Liechtenstein',
  },
  { isocode: 'LTU', code: '370', name: 'Lithuania', namePtBR: 'Lituânia' },
  { isocode: 'LUX', code: '352', name: 'Luxembourg', namePtBR: 'Luxemburgo' },
  // M
  { isocode: 'MAC', code: '853', name: 'Macau', namePtBR: 'Macau' },
  { isocode: 'MKD', code: '389', name: 'Macedonia', namePtBR: 'Macedônia' },
  { isocode: 'MDG', code: '261', name: 'Madagascar', namePtBR: 'Madagascar' },
  { isocode: 'MWI', code: '265', name: 'Malawi', namePtBR: 'Malawi' },
  { isocode: 'MYS', code: '60', name: 'Malaysia', namePtBR: 'Malásia' },
  { isocode: 'MDV', code: '960', name: 'Maldives', namePtBR: 'Maldivas' },
  { isocode: 'MLI', code: '223', name: 'Mali', namePtBR: 'Mali' },
  { isocode: 'MLT', code: '356', name: 'Malta', namePtBR: 'Malta' },
  {
    isocode: 'MHL', code: '692', name: 'Marshall Islands',
    namePtBR: 'Ilhas Marshall',
  },
  { isocode: 'MRT', code: '222', name: 'Mauritania', namePtBR: 'Mauritânia' },
  { isocode: 'MUS', code: '230', name: 'Mauritius', namePtBR: 'Maurício' },
  { isocode: 'MYT', code: '262', name: 'Mayotte', namePtBR: 'Mayotte' },
  { isocode: 'MEX', code: '52', name: 'Mexico', namePtBR: 'México' },
  { isocode: 'FSM', code: '691', name: 'Micronesia', namePtBR: 'Micronésia' },
  { isocode: 'MDA', code: '373', name: 'Moldova', namePtBR: 'Moldávia' },
  { isocode: 'MCO', code: '377', name: 'Monaco', namePtBR: 'Mônaco' },
  { isocode: 'MNG', code: '976', name: 'Mongolia', namePtBR: 'Mongólia' },
  { isocode: 'MNE', code: '382', name: 'Montenegro', namePtBR: 'Montenegro' },
  { isocode: 'MSR', code: '1-664', name: 'Montserrat', namePtBR: 'Montserrat' },
  { isocode: 'MAR', code: '212', name: 'Morocco', namePtBR: 'Marrocos' },
  { isocode: 'MOZ', code: '258', name: 'Mozambique', namePtBR: 'Moçambique' },
  { isocode: 'MMR', code: '95', name: 'Myanmar', namePtBR: 'Mianmar' },
  // N
  { isocode: 'NAM', code: '264', name: 'Namibia', namePtBR: 'Namíbia' },
  { isocode: 'NRU', code: '674', name: 'Nauru', namePtBR: 'Nauru' },
  { isocode: 'NPL', code: '977', name: 'Nepal', namePtBR: 'Nepal' },
  { isocode: 'NLD', code: '31', name: 'Netherlands', namePtBR: 'Holanda' },
  {
    isocode: 'ANT', code: '599', name: 'Netherlands Antilles',
    namePtBR: 'Antilhas Holandesas',
  },
  {
    isocode: 'NCL', code: '687', name: 'New Caledonia',
    namePtBR: 'Nova Caledônia',
  },
  {
    isocode: 'NZL', code: '64', name: 'New Zealand',
    namePtBR: 'Nova Zelândia',
  },
  { isocode: 'NIC', code: '505', name: 'Nicaragua', namePtBR: 'Nicarágua' },
  { isocode: 'NER', code: '227', name: 'Niger', namePtBR: 'Níger' },
  { isocode: 'NGA', code: '234', name: 'Nigeria', namePtBR: 'Nigéria' },
  { isocode: 'NIU', code: '683', name: 'Niue', namePtBR: 'Niue' },
  {
    isocode: 'PRK', code: '850', name: 'North Korea',
    namePtBR: 'Coreia do Norte',
  },
  {
    isocode: 'MNP', code: '1-670', name: 'Northern Mariana Islands',
    namePtBR: 'Ilhas Marianas do Norte',
  },
  { isocode: 'NOR', code: '47', name: 'Norway', namePtBR: 'Noruega' },
  // O
  { isocode: 'OMN', code: '968', name: 'Oman', namePtBR: 'Omã' },
  // P
  { isocode: 'PAK', code: '92', name: 'Pakistan', namePtBR: 'Paquistão' },
  { isocode: 'PLW', code: '680', name: 'Palau', namePtBR: 'Palau' },
  { isocode: 'PSE', code: '970', name: 'Palestine', namePtBR: 'Palestina' },
  { isocode: 'PAN', code: '507', name: 'Panama', namePtBR: 'Panamá' },
  {
    isocode: 'PNG', code: '675', name: 'Papua New Guinea',
    namePtBR: 'Papua Nova Guiné',
  },
  { isocode: 'PRY', code: '595', name: 'Paraguay', namePtBR: 'Paraguai' },
  { isocode: 'PER', code: '51', name: 'Peru', namePtBR: 'Peru' },
  { isocode: 'PHL', code: '63', name: 'Philippines', namePtBR: 'Filipinas' },
  { isocode: 'PCN', code: '64', name: 'Pitcairn', namePtBR: 'Pitcairn' },
  { isocode: 'POL', code: '48', name: 'Poland', namePtBR: 'Polônia' },
  { isocode: 'PRT', code: '351', name: 'Portugal', namePtBR: 'Portugal' },
  {
    isocode: 'PRI', code: '1-787', name: 'Puerto Rico',
    namePtBR: 'Porto Rico',
  },
  // Q
  { isocode: 'QAT', code: '974', name: 'Qatar', namePtBR: 'Catar' },
  // R
  {
    isocode: 'COG', code: '242', name: 'Republic of the Congo',
    namePtBR: 'República do Congo',
  },
  { isocode: 'REU', code: '262', name: 'Reunion', namePtBR: 'Reunião' },
  { isocode: 'ROU', code: '40', name: 'Romania', namePtBR: 'Romênia' },
  { isocode: 'RUS', code: '7', name: 'Russia', namePtBR: 'Rússia' },
  { isocode: 'RWA', code: '250', name: 'Rwanda', namePtBR: 'Ruanda' },
  // S
  {
    isocode: 'BLM', code: '590', name: 'Saint Barthelemy',
    namePtBR: 'São Bartolomeu',
  },
  {
    isocode: 'SHN', code: '290', name: 'Saint Helena',
    namePtBR: 'Santa Helena',
  },
  {
    isocode: 'KNA', code: '1-869', name: 'Saint Kitts and Nevis',
    namePtBR: 'São Cristóvão e Nevis',
  },
  {
    isocode: 'LCA', code: '1-758', name: 'Saint Lucia',
    namePtBR: 'Santa Lúcia',
  },
  {
    isocode: 'MAF', code: '590', name: 'Saint Martin',
    namePtBR: 'São Martinho',
  },
  {
    isocode: 'SPM', code: '508', name: 'Saint Pierre and Miquelon',
    namePtBR: 'São Pedro e Miquelão',
  },
  {
    isocode: 'VCT', code: '1-784', name: 'Saint Vincent and the Grenadines',
    namePtBR: 'São Vicente e Granadinas',
  },
  { isocode: 'WSM', code: '685', name: 'Samoa', namePtBR: 'Samoa' },
  { isocode: 'SMR', code: '378', name: 'San Marino', namePtBR: 'San Marino' },
  {
    isocode: 'STP', code: '239', name: 'Sao Tome and Principe',
    namePtBR: 'São Tomé e Príncipe',
  },
  {
    isocode: 'SAU', code: '966', name: 'Saudi Arabia',
    namePtBR: 'Arábia Saudita',
  },
  { isocode: 'SEN', code: '221', name: 'Senegal', namePtBR: 'Senegal' },
  { isocode: 'SRB', code: '381', name: 'Serbia', namePtBR: 'Sérvia' },
  { isocode: 'SYC', code: '248', name: 'Seychelles', namePtBR: 'Seychelles' },
  { isocode: 'SLE', code: '232', name: 'Sierra Leone', namePtBR: 'Serra Leoa' },
  { isocode: 'SGP', code: '65', name: 'Singapore', namePtBR: 'Cingapura' },
  {
    isocode: 'SXM', code: '1-721', name: 'Sint Maarten',
    namePtBR: 'Sint Maarten',
  },
  { isocode: 'SVK', code: '421', name: 'Slovakia', namePtBR: 'Eslováquia' },
  { isocode: 'SVN', code: '386', name: 'Slovenia', namePtBR: 'Eslovênia' },
  {
    isocode: 'SLB', code: '677', name: 'Solomon Islands',
    namePtBR: 'Ilhas Salomão',
  },
  { isocode: 'SOM', code: '252', name: 'Somalia', namePtBR: 'Somália' },
  {
    isocode: 'ZAF', code: '27', name: 'South Africa',
    namePtBR: 'África do Sul',
  },
  {
    isocode: 'KOR', code: '82', name: 'South Korea',
    namePtBR: 'Coreia do Sul',
  },
  {
    isocode: 'SSD', code: '211', name: 'South Sudan',
    namePtBR: 'Sudão do Sul',
  },
  { isocode: 'ESP', code: '34', name: 'Spain', namePtBR: 'Espanha' },
  { isocode: 'LKA', code: '94', name: 'Sri Lanka', namePtBR: 'Sri Lanka' },
  { isocode: 'SDN', code: '249', name: 'Sudan', namePtBR: 'Sudão' },
  { isocode: 'SUR', code: '597', name: 'Suriname', namePtBR: 'Suriname' },
  {
    isocode: 'SJM', code: '47', name: 'Svalbard and Jan Mayen',
    namePtBR: 'Svalbard e Jan Mayen',
  },
  { isocode: 'SWZ', code: '268', name: 'Swaziland', namePtBR: 'Suazilândia' },
  { isocode: 'SWE', code: '46', name: 'Sweden', namePtBR: 'Suécia' },
  { isocode: 'CHE', code: '41', name: 'Switzerland', namePtBR: 'Suíça' },
  { isocode: 'SYR', code: '963', name: 'Syria', namePtBR: 'Síria' },
  // T
  { isocode: 'TWN', code: '886', name: 'Taiwan', namePtBR: 'Taiwan' },
  { isocode: 'TJK', code: '992', name: 'Tajikistan', namePtBR: 'Tajiquistão' },
  { isocode: 'TZA', code: '255', name: 'Tanzania', namePtBR: 'Tanzânia' },
  { isocode: 'THA', code: '66', name: 'Thailand', namePtBR: 'Tailândia' },
  { isocode: 'TGO', code: '228', name: 'Togo', namePtBR: 'Togo' },
  { isocode: 'TKL', code: '690', name: 'Tokelau', namePtBR: 'Tokelau' },
  { isocode: 'TON', code: '676', name: 'Tonga', namePtBR: 'Tonga' },
  {
    isocode: 'TTO', code: '1-868', name: 'Trinidad and Tobago',
    namePtBR: 'Trinidad e Tobago',
  },
  { isocode: 'TUN', code: '216', name: 'Tunisia', namePtBR: 'Tunísia' },
  { isocode: 'TUR', code: '90', name: 'Turkey', namePtBR: 'Turquia' },
  {
    isocode: 'TKM', code: '993', name: 'Turkmenistan',
    namePtBR: 'Turcomenistão',
  },
  {
    isocode: 'TCA', code: '1-649', name: 'Turks and Caicos Islands',
    namePtBR: 'Ilhas Turcas e Caicos',
  },
  { isocode: 'TUV', code: '688', name: 'Tuvalu', namePtBR: 'Tuvalu' },
  // U
  {
    isocode: 'VIR', code: '1-340', name: 'U.S. Virgin Islands',
    namePtBR: 'EUA Ilhas Virgens',
  },
  { isocode: 'UGA', code: '256', name: 'Uganda', namePtBR: 'Uganda' },
  { isocode: 'UKR', code: '380', name: 'Ukraine', namePtBR: 'Ucrânia' },
  {
    isocode: 'ARE', code: '971', name: 'United Arab Emirates',
    namePtBR: 'Emirados Árabes Unidos',
  },
  {
    isocode: 'GBR', code: '44', name: 'United Kingdom',
    namePtBR: 'Reino Unido',
  },
  {
    isocode: 'USA', code: '1', name: 'United States',
    namePtBR: 'Estados Unidos',
  },
  { isocode: 'URY', code: '598', name: 'Uruguay', namePtBR: 'Uruguai' },
  { isocode: 'UZB', code: '998', name: 'Uzbekistan', namePtBR: 'Uzbequistão' },
  // V
  { isocode: 'VUT', code: '678', name: 'Vanuatu', namePtBR: 'Vanuatu' },
  { isocode: 'VAT', code: '379', name: 'Vatican', namePtBR: 'Vaticano' },
  { isocode: 'VEN', code: '58', name: 'Venezuela', namePtBR: 'Venezuela' },
  { isocode: 'VNM', code: '84', name: 'Vietnam', namePtBR: 'Vietnã' },
  // W
  {
    isocode: 'WLF', code: '681', name: 'Wallis and Futuna',
    namePtBR: 'Wallis e Futuna',
  },
  {
    isocode: 'ESH', code: '212', name: 'Western Sahara',
    namePtBR: 'Saara Ocidental',
  },
  // Y
  { isocode: 'YEM', code: '967', name: 'Yemen', namePtBR: 'Iêmen' },
  // Z
  { isocode: 'ZMB', code: '260', name: 'Zambia', namePtBR: 'Zâmbia' },
  { isocode: 'ZWE', code: '263', name: 'Zimbabwe', namePtBR: 'Zimbábue' },
];

export default getCountries;