
module.exports = {
  removeNonNumber: str => {
    return str.replace(/\D||,/g, '');
  },
  SEVERITY_ERROR: 'error',
  SEVERITY_WARNING: 'warning',
  SEVERITY_INFO: 'info',
  SEVERITY_SUCESS: 'success',
};
