import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import React from 'react';
import _ from 'lodash';

function TimePickerController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    disabled,
    onChangeDate,
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <TimePicker
          id={id}
          sx={{ width: '100%' }}
          label={label}
          value={value}
          onChange={ (value) => {
            if (!_.isNil(onChangeDate)) {
              onChangeDate(value);
            } else {
              onChange(value);
            }
          } }
          disabled={ disabled }
          renderInput={ params => (
            <TextField
              { ...params }
              fullWidth
              error={ invalid }
              helperText={ error?.message || '' }
            />
          ) }
        />
      )}
    />
  );
}

export default TimePickerController;