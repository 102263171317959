import { 
  isValid, parseISO, addMonths, isBefore, parse,
} from 'date-fns';

import _ from 'lodash';

function parseRecurringObjectToRRuleString(recurring) {
  if (!_.isNil(recurring)) {
    if (!_.isNil(recurring.repeat) && !_.isNil(recurring.interval)) {

      let rruleString = '';

      // FREQUENCY AND INTERVAL ARE NECCESSARY FOR EVERY RULE
      const frequency = 'FREQ=' + _.upperCase(recurring.repeat);
      rruleString += frequency;
  
      const interval = 'INTERVAL=' + recurring.interval;
      rruleString += ';' + interval;

      if (!_.isNil(recurring.until)) {
        let dateString = '';

        const oneMonthForward = parseDateToUntilOneMonthRule(recurring.until);
        dateString = oneMonthForward;

        if (isBefore(parseISO(recurring.until), parseISO(oneMonthForward))) {
          const date = parseISO(recurring.until);
          const utcDate = date.toJSON();
          const firstFormat = utcDate.replace(/[-,:]/g, '');
          const formattedDate = firstFormat.split('.')[0] + 'Z';
          dateString = formattedDate;
        }

        const until = 'UNTIL=' + dateString;
        rruleString += ';' + until;
      }

      if (!_.isNil(recurring.count)) {
        const count = 'COUNT=' + recurring.count;
        rruleString += ';' + count;
      }

      // WEEKLY RULES

      if (!_.isNil(recurring.weekDays)) {
        const byday = 'BYDAY=' + recurring.weekDays;
        rruleString += ';' + byday;
      }

      // MONTHLY RULES

      if (!_.isNil(recurring.day)) {
        const bymonthday = 'BYMONTHDAY=' + recurring.day;
        rruleString += ';' + bymonthday;
      }

      if (!_.isNil(recurring.pos)) {
        const bysetpos = 'BYSETPOS=' + recurring.pos;
        rruleString += ';' + bysetpos;
      }

      // YEARLY RULES

      if (!_.isNil(recurring.month)) {
        const month = 'BYMONTH=' + recurring.month;
        rruleString += ';' + month;
      }

      return rruleString;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function parseDateToDateStartRule(strDate) {
  const date = parseISO(strDate);

  if (isValid(date)) {
    const newDate = new Date(Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ));
    
    const utcDate = newDate.toJSON();
    const firstFormat = utcDate.replace(/[-,:]/g, '');
    const formattedDate = firstFormat.split('.')[0] + 'Z';
    return formattedDate;
  } else {
    return null;
  }
}

function parseDateToUntilOneMonthRule(strDate) {
  const date = parseISO(strDate);
  if (isValid(date)) {
    const now = new Date();
    date.setFullYear(now.getUTCFullYear());
    date.setMonth(now.getUTCMonth());
    date.setDate(now.getUTCDate());

    const until = addMonths(date, 1);
    const utcDate = until.toJSON();
    const firstFormat = utcDate.replace(/[-,:]/g, '');
    const formattedDate = firstFormat.split('.')[0] + 'Z';
    return formattedDate;
  } else {
    return null;
  }
}

function parseRecurringExceptionListToRRule(strDate, recurringException) {
  const rrule = [];

  recurringException.forEach((dateString) => {
    const date = parse(dateString, 'yyyy-MM-dd', new Date());

    if (isValid(date)) {
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth();
      const day = date.getUTCDate();
  
      const dateStart = parseISO(strDate);
      if (isValid(dateStart)) {
        const hours = dateStart.getUTCHours();
        const minutes = dateStart.getUTCMinutes();
        const seconds = dateStart.getUTCSeconds();
  
        const newDate = new Date(Date.UTC(
          year,
          month,
          day,
          hours,
          minutes,
          seconds,
        ));
  
        rrule.push(newDate);
      }
    }
  });

  return rrule;
}

function parseDateStartAndRecurringObjectToRRuleString(
  datestart, recurring,
) {
  const dtstart = parseDateToDateStartRule(datestart);
  const rrule = parseRecurringObjectToRRuleString(recurring);

  let until = '';
  if (_.isNil(recurring.until) && _.isNil(recurring.count)) {
    until = ';UNTIL=' + parseDateToUntilOneMonthRule(datestart);
  }

  if (!_.isNil(dtstart) && !_.isNil(rrule)) {
    return rrule + until;
  } else {
    return null;
  }
}

export {
  parseRecurringObjectToRRuleString,
  parseDateToDateStartRule,
  parseDateStartAndRecurringObjectToRRuleString,
  parseRecurringExceptionListToRRule,
};