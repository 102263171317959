import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Eventcalendar, setOptions, localePtBR } from '@mobiscroll/react';
import Dialog from '@mui/material/Dialog';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import NewSchedule from './NewSchedule';
import ReSchedule from './ReSchedule';
import ScheduleToolTip from './ScheduleToolTip';
import SessionRecord from './SessionRecord';

import Backdrop from '@mui/material/Backdrop';
import ClientDialog from '../Client/ClientDialog';

import '../../assets/css/Scroll.css';
import '../../assets/css/AppBar.css';

const scheduleService = require('../../service/ScheduleService');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

setOptions({
  locale: localePtBR,
  theme: 'ios',
  themeVariant: 'light',
});

export default function Calendar() {
  const [isOpen, setOpen] = useState(false);
  const [isMedicalRecordOpen, setMedicalRecordOpen] = useState(false);
  const [isToolTipOpen, setToolTipOpen] = useState(false);
  const [isReScheduleOpen, setReScheduleOpen] = useState(false);
  const [scheduleStartDate, setScheduleStartDate] = useState(new Date());
  const [schedules, setSchedules] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentEvent, setCurrentEvent] = useState({
    title: '',
    color: '#fffff',
    ClientId: {
      name: '',
      surname: '',
      cellphone: '',
    },
  });
  const timerRef = useRef(null);
  const [isClientDialogOpen, setClientDialogOpen] = useState(false);

  const handleCloseClientDialog = () => {
    setClientDialogOpen(false);
  };

  const openClientInfoDialog = () => {
    setClientDialogOpen(true);
  };

  const openReScheduleDialog = () => {
    setReScheduleOpen(true);
  };

  const handleEventClick = (args) => {
    setCurrentEvent(args.event);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setAnchorEl(args.domEvent.target);
    setToolTipOpen(true);
  };

  const handleCloseTooltip = () => {
    setToolTipOpen(false);
    setAnchorEl(null);
  };

  const handleEventCreate = () => {
    if (isToolTipOpen) {
      return false;
    }
  };

  const medicalConsultationStarted = () => {
    setMedicalRecordOpen(true);
    setToolTipOpen(false);
  };

  const onEventCreated = useCallback(args => {
    /*
     * fill popup form with event data
     * open the popup
     */

    setScheduleStartDate(args.event.start);
    setOpen(true);
  }, []);

  // popup options
  const handleClose = () => {
    setOpen(false);
    getSchedules();
  };

  const handleMedicalConsultationClose = () => {
    setMedicalRecordOpen(false);
    getSchedules();
  };

  const handleReScheduleClose = () => {
    setReScheduleOpen(false);
    getSchedules();
  };

  const getSchedules = useCallback(() => {
    setOpenBackDrop(true);
    scheduleService
      .getByUser()
      .then(response => {
        handleSchedules(response.data);
      })
      .catch(() => {
        setOpenBackDrop(false);
      })
      .finally(() => {
        setOpenBackDrop(false);
      });
  }, []);

  const handleSchedules = schedules => {
    for (let index = 0; index < schedules.length; index++) {
      schedules[index].start = new Date(schedules[index].start);
      schedules[index].end = new Date(schedules[index].end);

      const name = schedules[index]['ClientId']['name'];
      const surname = schedules[index]['ClientId']['surname'];
      // const cpf = schedules[index]['ClientId']['cpf'];
      schedules[index]['title'] =  name + ' ' + surname;
    }
    setSchedules(schedules);
  };

  const view = React.useMemo(() => {
    return {
      schedule: {
        type: 'week',
        allDay: false,
      },
    };
  }, []);

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  return (
    <>
      <Eventcalendar
        className={isToolTipOpen ? 'stopScroll' : 'scrollY'}
        onEventCreate={ handleEventCreate }
        clickToCreate="single"
        dragToCreate={false}
        dragToMove={true}
        dragToResize={true}
        eventDelete={true}
        onEventCreated={ onEventCreated }
        locale={localePtBR}
        onEventClick={ handleEventClick }
        data={schedules}
        view={ view }
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      />
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={ { ml: 2, flex: 1, fontSize: '1.375rem;' } }
              variant="subtitle1">
              Agendamento
            </Typography>
          </Toolbar>
        </AppBar>
        <NewSchedule
          scheduleStartDate={scheduleStartDate}
          handleClose={ handleClose }
          actionName="register"
        />
      </Dialog>
      <Dialog
        fullScreen
        open={isMedicalRecordOpen}
        onClose={handleMedicalConsultationClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMedicalConsultationClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={ {
                ml: 2, flex: 1, fontSize: '1.375rem;',
              } }
              variant="subtitle1">
              Prontuário
            </Typography>
          </Toolbar>
        </AppBar>
        <SessionRecord
          currentEvent={currentEvent}
          handleClose={handleMedicalConsultationClose}
        />
      </Dialog>
      <Dialog
        fullScreen
        open={isClientDialogOpen}
        onClose={handleCloseClientDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseClientDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, fontSize: '1.375rem;' }}        
              variant="subtitle1">
              Cliente
            </Typography>
          </Toolbar>
        </AppBar>
        <ClientDialog
          handleCloseDialog={ handleCloseClientDialog }
          actionName='view'
          clientId={ currentEvent.ClientId._id }
        />
      </Dialog>
      <Dialog
        fullScreen
        open={isReScheduleOpen}
        onClose={handleReScheduleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleReScheduleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={ {
              ml: 2,
              mr: 4,
              fontSize: '1.375rem;',
            } }
            variant="subtitle1">
              Re-agendar
            </Typography>
          </Toolbar>
        </AppBar>
        <ReSchedule
          handleClose={ handleReScheduleClose }
          schedule={ currentEvent }
        />
      </Dialog>
      <ScheduleToolTip
        open={isToolTipOpen}
        anchorEl={anchorEl}
        medicalConsultationStarted={ medicalConsultationStarted }
        openClientInfoDialog={ openClientInfoDialog }
        openReScheduleDialog={ openReScheduleDialog }
        currentEvent={ currentEvent }
        closeToolTip={ handleCloseTooltip }
      />
    </>
  );
}
