import React, { useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Backdrop from '@mui/material/Backdrop';

import { format, isEqual, parseISO } from 'date-fns';

import _ from 'lodash';

import Alert from '@mui/material/Alert';

// css
import '../../assets/css/Wrappers.css';

const miscUtils = require('../../utils/MiscellaneousUtils');

const sessionRecordService = require('../../service/SessionRecordService');

export default function SessionRecord(props) {
  const [description, setDescription] = React.useState('');
  const [evolution, setEvolution] = React.useState('');
  const [sessions, setSessions] = React.useState([]);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [needRefresh, setNeedRefresh] = React.useState(false);
  const [severity, setSeverity] = React.useState(miscUtils.SEVERITY_SUCESS);
  const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [start, setStart] = React.useState('');
  const [currentSession, setCurrentSession] = React.useState('Nova Sessão');

  const handleSessionCLicked = index => {
    const tmpSessions = sessions.map(e => {
      if (e.index === index) {
        e.selected = true;
        setDescription(e.description);
        setEvolution(e.evolution);
        setStart(e.start);
        setCurrentSession(e.index === 0 ? 'Nova Sessão' : e.index);
      } else {
        e.selected = false;
      }
      return e;
    });
    setSessions(tmpSessions);
  };

  const handleRegister = useCallback(() => {
    if (description === '') {
      showMessage('Preencha o campo descrição', miscUtils.SEVERITY_ERROR);
    } else if (evolution === '') {
      showMessage('Preencha o campo evolução', miscUtils.SEVERITY_ERROR);
    } else {
      setOpenBackDrop(true);
      // console.log(props.currentEvent);
      sessionRecordService
        .register({
          evolution,
          description,
          start: start,
          ClientId: props.currentEvent.ClientId._id,
          scheduleId: props.currentEvent._id,
          occurrenceId: props.currentEvent.occurrenceId,
        })
        .then(() => {
          showMessage('Dados salvos com sucesso!', miscUtils.SEVERITY_SUCESS);
          setOpenBackDrop(false);
          setNeedRefresh(!needRefresh);
        })
        .catch(() => {
          showMessage('Erro ao salvar os dados', miscUtils.SEVERITY_ERROR);
          // console.log(error);
          setOpenBackDrop(false);
        })
        .finally(() => {
          props.handleClose();
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, evolution, needRefresh, props.currentEvent, start]);

  useEffect(() => {
    setStart(props.currentEvent.start);
    setNeedRefresh(!needRefresh);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      setOpenBackDrop(true);
      // console.log(props.currentEvent.ClientId._id);
      sessionRecordService
        .getByClient(
          props.currentEvent.ClientId._id,
        )
        .then((response) => {
          setOpenBackDrop(false);
          let isNewSession = true;
          const localSessions = response.data.map(session => {
            if (!_.isString(start) && isEqual(parseISO(session.start), start)) {
              session.selected = true;
              setDescription(session.description);
              setEvolution(session.evolution);
              setCurrentSession(session.index);
              isNewSession = false;
            } else {
              session.selected = false;
            }
            return session;
          });
          if (isNewSession) {
            localSessions.unshift({
              index: 0,
              evolution: '',
              description: '',
              selected: true,
              start: start,
            });
          }
          setSessions(localSessions);
          setOpenBackDrop(false);
        })
        .catch(() => {
          setOpenBackDrop(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [needRefresh],
  );

  const showMessage = function (message, severity) {
    setSeverity(severity);
    setAlertMessage(message);
    setOpenAlertMessage(true);
    setTimeout(() => {
      setOpenAlertMessage(false);
    }, 5000);
  };

  const handleSubmit = useCallback(() => {
    handleRegister();
  }, [handleRegister]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleSubmit]);

  const formatDateString = (strDate, options) => {
    if (_.isEmpty(strDate)) {
      return ' ' + format(new Date(), options);
    } else {
      const date = parseISO(strDate);
      return ' ' + format(date, options);
    }
  };

  return (
    <div className="wrapper-fullscreen">
      <Grid
        container
        spacing={ 1 }
        rowSpacing={ 2 }
        sx={ {
          margin: '0px', paddingTop: 2,
          paddingLeft: '20px;', paddingBottom: '20px;',
        } }
      >
        <Backdrop
          sx={ { color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 } }
          open={ openBackDrop }
        />
        <Grid paddingTop={ 3 } item xs={ 11 }>
          <Alert
            severity={ severity }
            sx={ { display: openAlertMessage ? '' : 'none' } }
          >
            { alertMessage }
          </Alert>
        </Grid>
        <Grid py={ 0 } my={ 0 } item xs={ 11 }>
          <h1 style={ { padding: '0px', margin: '0px' } }>Prontuário</h1>
        </Grid>
        <Grid item xs={ 7 }>
          <Grid item xs={ 12 }>
            <b> Cliente:</b>
            { props.currentEvent.ClientId.name }{ ' ' }
            { props.currentEvent.ClientId.surname }
          </Grid>
          <Grid item xs={ 12 }>
            <p>
              <b>
                Sessão:
              </b>
              [{ currentSession }]
              -
              { formatDateString(start, 'P') }{ ' ' }
              { formatDateString(start, 'p') }
            </p>
          </Grid>
          <Grid item xs={ 12 }>
            <h2>Descrição</h2>
          </Grid>
          <Grid item xs={ 12 }>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={ 10 }
              xs={ 12 }
              onChange={ e => {
                setDescription(e.target.value);
              } }
              value={ description }
              style={ { width: '95%' } }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <h2>Evolução</h2>
          </Grid>
          <Grid item xs={ 12 }>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={ 10 }
              xs={ 12 }
              onChange={ e => {
                setEvolution(e.target.value);
              } }
              value={ evolution }
              style={ { width: '95%' } }
            />
          </Grid>
        </Grid>
        <Grid item xs={ 4 }>
          <h4>
            <b>Sessões Anteriores: </b>
          </h4>

          { sessions.map(({ index, start, selected }) => {
            return (
              <Button
                component={ Typography }
                key={ index }
                style={
                  selected
                    ? { fontWeight: 'bold' }
                    : { cursor: 'pointer' }
                }
                sx={ {
                  color: '#000',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  width: '100%',
                  justifyContent: 'flex-start',
                } }
                disableRipple
                onClick={ () => handleSessionCLicked(index) }
              >
                [{ index === 0 ? 'Nova Sessão' : index }]
                { formatDateString(start, 'P') }{ ' ' }
                { formatDateString(start, 'p') }
                <VisibilityIcon
                  sx={ { pl: 2 } }
                />
              </Button>
            );
          }) }
        </Grid>

        <Grid item xs={ 3 }>
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={ handleRegister }
            startIcon={ <SaveIcon /> }
          >
            Salvar
          </Button>
        </Grid>
        <Grid item xs={ 3 }>
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="error"
            onClick={ props.handleClose }
            startIcon={ <CancelIcon /> }
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
