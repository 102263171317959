import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

function MultilineTextFieldController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    rows,
    defaultHelperTextMessage,
    disabled,
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <TextField
          value={ value }
          onChange={ onChange }
          onBlur={ onBlur }
          inputRef={ ref }
          label={ label }
          id={ id }
          fullWidth
          variant="outlined"
          helperText={ error?.message || defaultHelperTextMessage }
          error={ invalid }
          multiline
          rows={ rows }
          disabled={disabled}
        />
      )}
    />
  );
}

export default MultilineTextFieldController;