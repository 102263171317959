const getKinship = () => [
  {
    label: 'Nenhum',
    value: '',
  },
  {
    label: 'Pai',
    value: 'Father',
  },
  {
    label: 'Mãe',
    value: 'Mother',
  },
  {
    label: 'Outro',
    value: 'Other',
  },
];

export default getKinship;