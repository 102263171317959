const getGenders = () => [
  {
    label: 'Todos',
    value: '',
  },
  {
    label: 'Não Informado',
    value: '?',
  },
  {
    label: 'Feminino',
    value: 'F',
  },
  {
    label: 'Masculino',
    value: 'M',
  },
];

export default getGenders;