import React from 'react';

import {
  Grid,
  Divider,
  Typography,
} from '@mui/material';

import {
  TextFieldController,
} from '../../../components/TextField';

import RadioController from
  '../../../components/Radio/RadioController';

import CheckboxController from
  '../../../components/Checkbox/CheckboxController';

function ClientFinancialFields({ control, isViewMode }) {
  return (
    <>
      <Grid item xs={ 12 }
        sx={ {
          paddingTop: '20px !important',
          paddingBottom: 0,
        } }>
        <Typography variant="h6" fontWeight={500}>
          Financeiro & Contrato
        </Typography>
      </Grid>
      <Grid item xs={ 12 }
        sx={ {
          paddingBottom: 5,
        } }>
        <Divider
          sx={ { height: 1 } }
          orientation="horizontal"
          flexItem
        />
      </Grid>
      <Grid item md={6} sm={ 12 }>
        <RadioController
          id="client-charge-textfield"
          name="client.chargeType"
          label="Tipo de Cobrança: "
          control={ control }
          options={ [
            { id: 'session', label: 'Por sessão' },
            { id: 'monthly', label: 'Mensal' },
          ]}
          disabled={isViewMode}
        />
      </Grid>
      <Grid item md={6} sm={ 12 }>
        <TextFieldController
          id="client-value-textfield"
          name="client.value"
          control={ control }
          disabled={ isViewMode }
          label="Valor *"
          isNumber={ true }
        />
      </Grid>
      <Grid item sm={ 12 } xs={ 12 } paddingBottom={ 5 }>
        <CheckboxController
          id="client-receipt-textfield"
          name="client.needsReceipt"
          label="Requer Recibo?"
          control={ control }
          disabled={ isViewMode }
        />
      </Grid>
    </>
  );
}

export default ClientFinancialFields;