import getToken from '../utils/getToken';
const token = getToken();

const axios = require('axios');
const URL = process.env.REACT_APP_API_URL;

export async function register(medicalRecord) {
  return axios.post(`${URL}v1/sessionRecord/register`, medicalRecord, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}

export async function registerByStatusAccomplished(medicalRecord) {
  return axios.post(`${ URL }v1/sessionRecord/registerByStatusAccomplished`,
    medicalRecord, {
      headers: {
        PSIHeaderAuth: `Bearer ${token}`,
      },
    });
}

export async function getByClient(client) {
  return axios.get(`${URL}v1/sessionRecord/findByClient`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      client: client,
    },
  });
}

export async function getByClientAndStatus(client) {
  return axios.get(`${URL}v1/sessionRecord/findByClientAndStatus`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      client: client,
    },
  });
}

export async function getAllByIdAndScheduleDate(
  id,
  scheduleDate,
  isPrevious = 'false',
) {
  return axios.get(`${URL}v1/sessionRecord/findByIdAndScheduleDate`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      id,
      scheduleDate,
      isPrevious,
    },
  });
}

export async function edit(payload) {
  return axios.patch(`${URL}v1/sessionRecord/edit`, payload, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}
