import {
  FormControl,
  TextField,
  MenuItem,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';
import _ from 'lodash';

function DropdownController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    options,
    defaultHelperTextMessage,
    disabled,
    handleChange,
  } = props;

  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={ option.value } value={ option.value }>
          { option.label }
        </MenuItem>
      );
    });
  };

  return (
    <FormControl fullWidth>
      <Controller
        name={ name }
        control={ control }
        rules={rules || undefined}
        render={ ({
          field: { value, onChange, onBlur, ref },
          fieldState: { invalid, error },
        }) => (
          <TextField
            select
            id={ id }
            label={ label }
            value={ value }
            onChange={ (e) => {
              onChange(e);
              if (!_.isNil(handleChange)) {
                handleChange(e.target.value);
              }
            }}
            onBlur={ onBlur }
            inputRef={ ref }
            error={ invalid }
            helperText={ error?.message || defaultHelperTextMessage }
            disabled={disabled}
          >
            { generateSelectOptions() }
          </TextField>
        )}
      />
    </FormControl>
  );
}

export default DropdownController;