import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { TextField, Button } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
// mask
import InputMask from 'react-input-mask';

// select
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';

import Box from '@mui/material/Box';

import Backdrop from '@mui/material/Backdrop';

// css
import '../../assets/css/Wrappers.css';

import ClientsDataGrid from './components/ClientsDataGrid';

import _ from 'lodash';

const clientService = require('../../service/ClientService');

export default function SearchClients() {
  const [name, setName] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [cpf, setCPF] = useState('');
  const [gender, setGender] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [requested, setRequested] = useState(false);

  const [clients, setClients] = React.useState([]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    if (newPage === 0) {
      setPreviousPage(null);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchClients = (currentPage, limit) => {
    const payload = {};

    if (!_.isEmpty(name)) {
      payload['name'] = name;
    }

    if (birthMonth !== 0 && birthMonth !== '') {
      payload['birthmonth'] = birthMonth;
    }

    if (!_.isEmpty(cpf)) {
      payload['cpf'] = cpf;
    }

    if (!_.isEmpty(gender)) {
      payload['gender'] = gender;
    }

    if (!_.isEmpty(cellphone)) {
      payload['cellphone'] = cellphone;
    }

    if (!_.isEmpty(email)) {
      payload['email'] = email;
    }

    setOpenBackDrop(true);
    clientService
      .searchClientsByUser(payload, currentPage, limit)
      .then(response => {
        const pagination = response.data.pagination;
        const clients = response.data.clients;
        setClients(clients);
        setTotalRowCount(pagination.totalCount);

        if (pagination.previous !== undefined) {
          setPreviousPage(pagination.previous.page);
        } else {
          setPreviousPage(null);
        }
      })
      .catch(() => {
        setOpenBackDrop(false);
      })
      .finally(() => {
        setRequested(true);
        setOpenBackDrop(false);
      });
  };

  const handleSubmit = useCallback(() => {
    setPreviousPage(0);
    setPage(0);
    searchClients(0, pageSize);
  }, [pageSize, searchClients]);

  useEffect(() => {
    if (previousPage !== page) {
      searchClients(page, pageSize);
    } else if (page === 0 && previousPage === null) {
      searchClients(page, pageSize);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  const pagination = useMemo(() => {
    return {
      page,
      pageSize,
      totalRowCount,
    };
  }, [page, pageSize, totalRowCount]);

  const handleChangeBirthMonth = (event) => {
    setBirthMonth(event.target.value);
  };

  const handleGender = event => {
    setGender(event.target.value);
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleSubmit]);

  return (
    <div className="wrapper-flex-fullscreen">
      <Grid
        container
        spacing={1}
        direction="row"
        rowSpacing={0.01}
        justifyContent="start"
        alignSelf="center"
        flexGrow={0.2}
        sx={ {
          px: '20px',
          pt: '50px',
          pb: '20px',
          margin: '0px',
          width: '100%',
          maxWidth: '1200px',
        } }
      >
        <Grid item xs={12} py={1}>
          <TextField
            id="name"
            label="Nome"
            value={name}
            onChange={e => setName(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4} py={1}>
          <FormControl fullWidth>
            <InputLabel id="birthmonth-label">Mês de Nascimento</InputLabel>
            <Select
              labelId="birithmonth-label"
              id="birthmonth"
              value={birthMonth}
              label="Mês de Nascimento"
              onChange={handleChangeBirthMonth}
            >
              <MenuItem value={''}>Todos</MenuItem>
              <MenuItem value={1}>Janeiro</MenuItem>
              <MenuItem value={2}>Fevereiro</MenuItem>
              <MenuItem value={3}>Março</MenuItem>
              <MenuItem value={4}>Abril</MenuItem>
              <MenuItem value={5}>Maio</MenuItem>
              <MenuItem value={6}>Junho</MenuItem>
              <MenuItem value={7}>Julho</MenuItem>
              <MenuItem value={8}>Agosto</MenuItem>
              <MenuItem value={9}>Setembro</MenuItem>
              <MenuItem value={10}>Outubro</MenuItem>
              <MenuItem value={11}>Novembro</MenuItem>
              <MenuItem value={12}>Dezembro</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} py={1}>
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            disabled={false}
            maskChar=" "
            onChange={e => setCPF(e.target.value)}
          >
            {() =>
              <TextField
                id="cpf"
                label="CPF"
                variant="outlined"
                fullWidth
              />}
          </InputMask>
        </Grid>
        <Grid item xs={4} py={1}>
          <FormControl fullWidth>
            <InputLabel id="gender-label">Sexo</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={ gender }
              label="Sexo"
              onChange={handleGender}
            >
              <MenuItem value={''}>Todos</MenuItem>
              <MenuItem value={'M'}>Masculino</MenuItem>
              <MenuItem value={'F'}>Feminino</MenuItem>
              <MenuItem value={'?'}>Não informado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <InputMask
            mask="(99)9999-99999"
            value={cellphone}
            disabled={false}
            maskChar=" "
            onChange={e => setCellphone(e.target.value)}
          >
            {() =>
              <TextField
                id="cellphone"
                label="Celular"
                variant="outlined"
                fullWidth
              />}
          </InputMask>
        </Grid>
        <Grid item xs={4} py={1}>
          <TextField
            id="email"
            label="E-mail"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              id="search-button"
              size="large"
              variant="contained"
              onClick={handleSubmit}
              startIcon={<SearchIcon />}
            >
              Pesquisar
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ClientsDataGrid
        data={ clients }
        isLoading={ openBackDrop }
        pagination={ pagination }
        handlePageChange={ handlePageChange }
        handlePageSizeChange={ handlePageSizeChange }
        search={ searchClients }
        resetPagination={ () => {
          setPreviousPage(0);
          setPage(0);
        }}
        requested={ requested } />
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      />
    </div>
  );
}
