/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import _ from 'lodash';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import {
  TextFieldController,
} from '../../components/TextField';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const userService = require('../../service/UserService');

const schema = z
  .object({
    password: z.string()
      .min(1, 'Campo senha é obrigatório'),
    confirmPassword: z.string()
      .min(1, 'Campo confirmar senha é obrigatório'),
  });

const defaultValues = {
  password: '',
  confirmPassword: '',
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Todos os direitos reservados © '}
      <Link color="inherit" href="https://www.cafecomjava.com.br/">
        Café com Java
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ResetPassword() {
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const handleToggle = useCallback(() => {
    setOpenBackDrop(!openBackDrop);
  }, [openBackDrop]);

  const handleClose = () => {
    setOpenBackDrop(false);
  };

  const onSubmit = (data) => {
    clearErrors();
    if (!_.isNil(state) && !_.isNil(state.token)) {
      if (_.isEqual(data.password, data.confirmPassword)) {
        userService
          .resetPassword(state.token, state.email, data.password)
          .then((response) => {
            if (response.status === 200) {
              window.location = '/';
            }
          })
          .finally(() => {
            handleClose();
          });
      } else {
        setError('confirmPassword', {
          message: 'As senhas precisam ser idênticas.',
        });
      }
    }
  };

  if (_.isNil(state)) {
    window.location = '/';
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="xs" sx={ {
        height: '100%',
        display: _.isNil(state) ? 'none' : 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Redefinir senha
          </Typography>
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            style={{ display: openErrorMessage ? '' : 'none' }}
          >
            <Alert severity="error">
              {errorMessage}
            </Alert>
          </Stack>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            autoComplete="off"
            sx={{ mt: 1,  width: '100%' }}
          >
            <Box py={1}>
              <TextFieldController
                id='password-texfield'
                label='Senha *'
                name='password'
                control={ control }
                type='password'
              />
            </Box>
            <Box py={1}>
              <TextFieldController
                id='confirm-password-texfield'
                label='Confirmar senha *'
                name='confirmPassword'
                control={ control }
                type='password'
              />
            </Box>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            />
            <Button
              type='submit'
              fullWidth
              variant="contained"
              // href="/Schedule"
              sx={{ mt: 3, mb: 2 }}
            >
              {' '}Redefinir
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
