const getSchooling = () => [
  {
    label: 'Ensino Fundamental',
    value: 'Ensino Fundamental',
  },
  {
    label: 'Ensino Medio',
    value: 'Ensino Medio',
  },
  {
    label: 'Ensino Superior',
    value: 'Ensino Superior',
  },
  {
    label: 'Ensino Fundamental Incompleto',
    value: 'Ensino Fundamental Incompleto',
  },
  {
    label: 'Ensino Medio Incompleto',
    value: 'Ensino Medio Incompleto',
  },
  {
    label: 'Ensino Superior Incompleto',
    value: 'Ensino Superior Incompleto',
  },
  {
    label: 'Ensino Superior Completo',
    value: 'Ensino Superior Completo',
  },
];

export default getSchooling;