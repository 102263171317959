import _ from 'lodash';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function getToken() {
  let token = null;

  if (!_.isNil(cookies.get('token'))) {
    token = cookies.get('token');
  } else if (!_.isNil(localStorage.getItem('token'))) {
    token = localStorage.getItem('token');
  }

  return token;
}

export default getToken;