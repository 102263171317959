import getToken from '../utils/getToken';
const token = getToken();

const axios = require('axios');
const URL = process.env.REACT_APP_API_URL;

export async function getClientById(id) {
  return axios.get(`${URL}v1/client/findClientById`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      clientId: id,
    },
  });
}

export async function updateClientById(id, payload) {
  return axios.patch(`${URL}v1/client/editClient`, payload, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      clientId: id,
    },
  });
}

export async function inactivateClientById(id) {
  return axios.patch(`${ URL }v1/client/inactivateClientById`, {}, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      clientId: id,
    },
  });
}

export async function register(payload) {
  return axios.post(`${URL}v1/client/register`, payload, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}

export async function getByUserAutocomplete() {
  return axios.get(`${URL}v1/client/findByUserAutoComplete`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
  });
}

export async function searchClientsByUser(search, page, limit) {
  return axios.get(`${ URL }v1/client/searchClientsByUser`, {
    headers: {
      PSIHeaderAuth: `Bearer ${token}`,
    },
    params: {
      ...search,
      page,
      limit,
    },
  });
}
