import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import _ from 'lodash';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function UnprotectedLayout() {
  const { auth } = useAuth();

  if (!_.isNil(auth) || !_.isNil(cookies.get('token'))) {
    return <Navigate to="/calendar" />;
  }

  return (
    <Outlet />
  );
}