import React from 'react';

import { setDefaultOptions } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  LocalizationProvider,
  ptBR as datePickerPtBR,
} from '@mui/x-date-pickers';
import { ptBR as dataGridPtBR } from '@mui/x-data-grid';
import { ptBR as corePtBR } from '@mui/material/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

setDefaultOptions({
  locale: ptBR,
});

const theme = createTheme(
  datePickerPtBR,
  dataGridPtBR,
  corePtBR,
);

export default function Container({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <main style={{
          height: 'calc(100% - 64px)',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}>
          {children}
        </main>
      </LocalizationProvider>
    </ThemeProvider>
  );
}