import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

function AutocompleteController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    options,
    disabled,
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <>
          <Autocomplete
            disablePortal
            id={ id }
            isOptionEqualToValue={
              (option, value) => option.label === value
            }
            onBlur={ onBlur }
            disabled={ disabled }
            value={ value }
            onChange={ (e, newValue) => onChange(newValue.label) }
            options={ options }
            fullWidth
            renderInput={
              (params) =>
                <TextField
                  { ...params }
                  inputRef={ ref }
                  label={ label }
                  error={ invalid }
                  disabled={disabled}
                />
            }
          />
          <p>{ error?.message }</p>
        </>
      )}
    />
  );
}

export default AutocompleteController;