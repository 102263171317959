import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import _ from 'lodash';

function RadioController(props) {

  const {
    name,
    control,
    label,
    rules,
    id,
    options,
    disabled,
    direction = 'row',
    radioPaddingBottom = 0,
    radioPaddingLeft = 8,
    handleChange,
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <FormControl style={ {
          flexDirection: direction,
        }} disabled={disabled}>
          <FormLabel id={ id + '-label' } style={ {
            paddingTop: 8,
          } }>
            { label }
          </FormLabel>
          <RadioGroup
            aria-labelledby={ id + '-label' }
            name={ name }
            value={ value }
            onChange={ (e) => {
              onChange(e);
              if (!_.isNil(handleChange)) {
                handleChange(e.target.value);
              }
            }}
            style={ {
              flexDirection: direction,
              paddingLeft: radioPaddingLeft,
            }}
          >
            {
              options.map((radio, index) => {
                return (
                  <FormControlLabel
                    key={ index }
                    value={ radio.id }
                    control={ <Radio /> }
                    label={ radio.label }
                    sx={ {
                      paddingBottom: radioPaddingBottom,
                    }}
                  />
                );
              })
            }
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}

export default RadioController;