import _ from 'lodash';
import { SEVERITY_ERROR } from '../../../utils/MiscellaneousUtils';
import validateClientFields from './validateClientFields';
import validateResponsibleFields from './validateResponsibleFields';

function validateFields(
  client,
  responsibles,
  showMessage,
  setFocus,
  setError,
  callback,
) {
  let failedAll = false;
  
  const {
    failedClient,
    needsFinancialResponsible,
  } = validateClientFields(client);

  const financialResponsible = responsibles
    .find((res) => res.isFinancial === true);

  if (_.isNil(financialResponsible) && needsFinancialResponsible) {
    showMessage(
      'É necessário declarar um responsável financeiro, ' + 
      'pois cliente é menor.',
      SEVERITY_ERROR,
    );
    setFocus('client.name');
    failedAll = true;

  } else if (_.isNil(financialResponsible)) {
    client.isFinancial = true;
  }

  if (failedClient === false) {
    if (failedAll === false) {

      if (needsFinancialResponsible) {
        let responsibleFailed = false;
        let index = 0;
        for (const res of responsibles) {
          responsibleFailed = validateResponsibleFields(index,
            res, showMessage, setFocus, setError,
          );
          if (responsibleFailed === true) {
            break;
          }
          index++;
        }
        if (responsibleFailed === false) {
          callback(client);
        }
      } else {
        callback(client);
      }
    }
  }
}

export default validateFields;