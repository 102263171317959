import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { NavLink } from 'react-router-dom';
import '../assets/css/Navbar.css';

const pages = [
  'Calendário',
  'Clientes',
  'Financeiro',
  'Relatórios',
  'Configurações',
];

const navlinks = [
  '/calendar',
  '/client',
  '/finance',
  '/reports',
  '/configs',
];

export default function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  return (
    <AppBar position="static">
      <Container
        maxWidth="false"
        sx={ {
          paddingLeft: '7px !important',
          paddingRight: '0px !important',
        } }>
        <Toolbar style={{ width:'100%' }} disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              { pages.map((page, index) => { 

                if (page === 'Clientes') {
                  return [
                    <MenuItem 
                      key={index + 'search'} 
                      onClick={ handleCloseNavMenu }
                    >
                      <NavLink style={{
                        textDecoration: 'none',
                        color: '#000',
                      }} to={ navlinks[index] + '/search' }>
                        <Typography
                          textAlign="center"
                          textTransform="none"
                          fontWeight={ 500 }
                          letterSpacing={ 1.5 }>
                            Pesquisar Clientes
                        </Typography>
                      </NavLink>
                    </MenuItem>,                    
                    <MenuItem
                      key={ index + 'register' }
                      onClick={ handleCloseNavMenu }
                    >
                      <NavLink style={{
                        textDecoration: 'none',
                        color: '#000',
                      }} to={ navlinks[index] + '/register' }>
                        <Typography
                          textAlign="center"
                          textTransform="none"
                          fontWeight={ 500 }
                          letterSpacing={ 1.5 }>
                          Cadastrar Cliente
                        </Typography>
                      </NavLink>
                    </MenuItem>,
                  ];
                }

                return (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <NavLink style={{
                      textDecoration: 'none',
                      color: '#000',
                    }} to={navlinks[index]}>
                      <Typography
                        textAlign="center"
                        textTransform="none"
                        fontWeight={ 500 }
                        letterSpacing={ 1.5 }>
                        { page }
                      </Typography>
                    </NavLink>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
          <CalendarIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontSize: '1.1rem',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            PSICO:AGENDA
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            { pages.map((page, index) => {
              if (page === 'Clientes') {
                return [
                  <Button
                    key={index + 'button'}
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ my: 0, mr: 2, color: 'white', display: 'block' }}
                  >
                    <Typography
                      textTransform="none"
                      fontWeight={ 500 }
                      letterSpacing={ 1.5 }>
                      { page }
                    </Typography>
                  </Button>,                  
                  <Menu
                    key={index + 'menu'}
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem onClick={ handleClose }>
                      <NavLink style={{
                        textDecoration: 'none',
                        color: '#000',
                      }} to={ navlinks[index] + '/search' }>
                        <Typography
                          textAlign="center"
                          textTransform="none"
                          fontWeight={ 400 }
                          letterSpacing={ 1.5 }>
                          Pesquisa
                        </Typography>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={ handleClose }>
                      <NavLink style={{
                        textDecoration: 'none',
                        color: '#000',
                      }} to={ navlinks[index] + '/register' }>
                        <Typography
                          textAlign="center"
                          textTransform="none"
                          fontWeight={ 400 }
                          letterSpacing={ 1.5 }>
                          Cadastro
                        </Typography>
                      </NavLink>
                    </MenuItem>
                  </Menu>,
                ];
              }

              return (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 0, mr: 2, color: 'white', display: 'block' }}
                >
                  <NavLink style={{
                    textDecoration: 'none',
                    color: 'white',
                  }} to={navlinks[index]}>
                    <Typography
                      textTransform="none"
                      fontWeight={ 500 }
                      letterSpacing={ 1.5 }>
                      { page }
                    </Typography>
                  </NavLink>
                </Button>
              );
            })}
          </Box>
          <CalendarIcon sx={ { display: { xs: 'none', md: 'flex' }, mr: 1 } } />
          <NavLink style={{
            textDecoration: 'none',
            color: 'white',
          }} to="/calendar">
            <Typography
              variant="h6"
              noWrap
              component="p"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontSize: '1rem',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              PSICO:AGENDA
            </Typography>
          </NavLink>
        </Toolbar>
      </Container>
    </AppBar>
  );
}