import React from 'react';
import { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

const clientService = require('../../../service/ClientService');

export default function InactivateClientDialog(props) {

  const handleOnInactivate = useCallback(async () => {
    clientService
      .inactivateClientById(props.clientId)
      .then((response) => {
        props.handleCloseDialog();
        if (response.status === 200) {
          props.resetPagination();
          props.search(0, 5);
        }
      })
      .catch(() => {
        props.handleCloseDialog();
      });
  }, [props]);

  const handleOnCancel = useCallback(() => {
    props.handleCloseDialog();
  }, [props]);

  return (
    <Grid container width={400} height={100}>
      <Typography
        variant="p"
        sx={{
          pt: 2,
          pl: 1,
          fontWeight: 400,
          flexGrow: 1,
          fontSize: '1.1rem',
        }}
      >
          Tem certeza que deseja desativar o cliente?
      </Typography>
      <Grid container alignContent="flex-end"
        spacing={ 1 }
        sx={ { pl: 1, pr: 1, pb: 1 } }>
        <Grid item xs={6}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={handleOnInactivate}
            startIcon={<DeleteIcon />}
          >
              Desativar
          </Button>
        </Grid>
        <Grid item xs={6} >
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="error"
            onClick={handleOnCancel}
            startIcon={<CancelIcon />}
          >
              Cancelar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}