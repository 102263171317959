import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { parseISO } from 'date-fns';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import '../../assets/css/Wrappers.css';

import validateFields from './utils/validateFields';

import {
  Box,
  Alert,
  Button,
  Backdrop,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';

import {
  Save as SaveIcon,
} from '@mui/icons-material';

import {
  MultilineTextFieldController,
} from '../../components/TextField';

import ClientFields from './components/ClientFields';
import ClientFinancialFields from './components/ClientFinancialFields';
import ClientAddress from './components/ClientAddress';
import ClientResponsiblesList from './components/ClientResponsiblesList';

import * as  clientService from '../../service/ClientService';
import isCPFNumber from '../../utils/isCPFNumber';

import '../../assets/css/Checkbox.css';

const miscUtils = require('../../utils/MiscellaneousUtils');

const schema = z
  .object({
    client: z.object({
      name: z.string().min(1, 'Nome é obrigatório'),
      surname: z.string().min(1, 'Sobrenome é obrigatório'),
      profession: z.string(),
      schooling: z.string(),
      gender: z.string(),
      maritalState: z.string(),
      cpf: z.string().min(11, 'CPF é obrigatório')
        .refine(isCPFNumber, (val) => ({
          message: `${val} não é um CPF válido`,
        })),
      rg: z.string(),
      cellphone: z.string(),
      email:  z.string().email('E-mail inválido')
        .optional()
        .or(z.literal('')),
      naturalness: z.string().nullable(),
      medicineReason: z.string(),
      therapyReason: z.string(),
      isFinancial: z.boolean(),
      birthDate: z.date(),
      chargeType: z.string(),
      needsReceipt: z.boolean(),
      value: z.string().min(1, 'Valor é obrigatório'),
    }),
    address: z.object({
      cep: z.string(),
      state: z.string(),
      city: z.string(),
      neighbourhood: z.string(),
      street: z.string(),
      number: z.string(),
      complement: z.string(),
    }),
    responsibles: z.array(
      z.object({
        _id: z.string().optional(),
        kinship: z.string().min(1, 'Parentesco é obrigatório')
          .nullable(),
        name: z.string().min(1, 'Nome é obrigatório')
          .nullable(),
        surname: z.string().min(1, 'Sobrenome é obrigatório')
          .nullable(),
        cpf: z.string().min(11, 'CPF é obrigatório')
          .refine(isCPFNumber, (val) => ({
            message: `${val} não é um CPF válido`,
          })),
        profession: z.string(),
        isFinancial: z.boolean(),
        birthDate: z.date(),
        deleted: z.boolean(),
        new: z.boolean(),
      }),
    ),
  });

const defaultValues = {
  client: {
    name: '',
    surname: '',
    profession: '',
    schooling: '',
    gender: '?',
    maritalState: '',
    cpf: '',
    rg: '',
    cellphone: '',
    email: '',
    naturalness: 'Brasil',
    medicineReason: '',
    therapyReason: '',
    isFinancial: false,
    birthDate: new Date('2000-01-01T03:00:00.000Z'),
    chargeType: 'session',
    needsReceipt: false,
    value: '0',
  },
  responsibles: [],
  address: {
    cep: '',
    state: '',
    city: '',
    neighbourhood: '',
    street: '',
    number: '',
    complement: '',
  },
};

function ClientDialog(props) {

  const [useMedicine, setUseMedicine] = useState(false);

  // Flags and Alerts
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState(Alert.SEVERITY_ERROR);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [isViewMode, setViewMode] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    setFocus,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const {
    fields: responsibleFields,
    append: appendResponsible,
    remove: removeResponsible,
    update: updateResponsible,
  } = useFieldArray({
    control,
    name: 'responsibles',
  });

  useEffect(() => {
    const firstLevelErrors = Object.keys(errors);
    if (firstLevelErrors.length > 0) {
      const field = firstLevelErrors[0];
      if (field === 'client') {
        const secondLevelErrors = Object.keys(errors[field]);
        const firstError = secondLevelErrors[0];
        if (firstError === 'cpf' || firstError === 'cellphone') {
          setFocus(`${ field }.${ 'name' }`);
          setTimeout(() => {
            setFocus(`${ field }.${ firstError }`);
          }, 1);
        } else {
          setFocus(`${ field }.${ firstError }`);
        }
      } else if (field === 'responsibles') {
        if (errors['responsibles'].length > 0) {
          const index = Object.keys(errors['responsibles'])[0];
          const responsible = errors['responsibles'][index];
          const fields = Object.keys(responsible);
          const fieldName = fields[0];
          if (fieldName === 'cpf') {
            setFocus(`${ field }[${ index }].${ 'name' }`);
            setTimeout(() => {
              setFocus(`${ field }[${ index }].${ fieldName }`);
            }, 1);
          } else {
            setFocus(`${ field }[${ index }].${ fieldName }`);
          }
        }
      }
    }

  }, [errors, setFocus]);

  useEffect(() => {
    if (props.actionName === 'view') {
      setViewMode(true);
    }
  }, [props.actionName]);

  const handleAddResponsible = () => {
    appendResponsible({
      kinship: '',
      name: '',
      surname: '',
      cpf: '',
      profession: '',
      isFinancial: false,
      birthDate: new Date('2000-01-01T03:00:00.000Z'),
      deleted: false,
      new: true,
    });
  };

  const handleRemoveResponsible = (index) => {
    if (responsibleFields[index].new) {
      removeResponsible(index);
    } else {
      updateResponsible(index, {
        ...responsibleFields[index],
        deleted: true,
      });
    }
  };

  const resetCheckboxes = () => {
    setValue('client.isFinancial', false);
    responsibleFields.forEach((res, index) => {
      setValue(`responsibles[${ index }].isFinancial`, false);
    });
  };

  const getClientById = useCallback(() => {
    const id = props.clientId;

    setOpenBackDrop(true);
    clientService
      .getClientById(id)
      .then(response => {
        setOpenBackDrop(false);

        const clientData = response.data.client;

        setValue('client', {
          name: clientData.name,
          surname: clientData.surname,
          email: clientData.email,
          profession: clientData.profession,
          gender: clientData.gender,
          cpf: clientData.cpf,
          rg: clientData.rg,
          cellphone: clientData.cellphone,
          naturalness: clientData.naturalness,
          schooling: clientData.schooling,
          maritalState: clientData.maritalState,
          isFinancial: clientData.isFinancial,
          medicineReason: clientData.medicineReason,
          therapyReason: clientData.therapyReason,
          birthDate: parseISO(clientData.birthDate),
          needsReceipt: clientData.needsReceipt,
          value: clientData.value,
          chargeType: clientData.chargeType,
        });

        const addressData = response.data.address;
        if (!_.isNil(addressData)) {
          setValue('address', addressData);
        }

        const responsiblesData = response.data.responsibles;
        const newResponsibles = [];

        if (!_.isNil(responsiblesData)) {
          for (const res of responsiblesData) {
            newResponsibles.push({
              _id: res._id,
              kinship: res.kinship,
              name: res.name,
              surname: res.surname,
              cpf: res.cpf,
              profession: res.profession,
              isFinancial: res.isFinancial,
              birthDate: parseISO(res.birthDate),
              deleted: false,
              new: false,
            });
          }
        }
        
        setValue('responsibles', newResponsibles);
      })
      .catch(error => {
        showMessage(
          `Ocorreu um erro inesperado: ${ error }`,
          miscUtils.SEVERITY_ERROR,
        );
        setOpenBackDrop(false);
      })
      .finally(() => {
        setOpenBackDrop(false);
      });
  }, [props.clientId, setValue]);

  useEffect(() => {
    if (props.actionName && props.actionName !== 'register') {
      getClientById(props.clientId);
    }
  }, [getClientById, props.actionName, props.clientId]);

  const showMessage = function (message, severity) {
    setSeverity(severity);
    setAlertMessage(message);
    setOpenAlertMessage(true);
  };

  const handleServiceResponse = useCallback((response) => {
    setOpenBackDrop(false);
    switch (response.status) {
    case 204:
      showMessage(
        'Cliente atualizado com sucesso',
        miscUtils.SEVERITY_SUCESS,
      );

      if (props.handleCloseDialog !== undefined &&
          props.search !== undefined) {
        props.resetPagination();
        props.handleCloseDialog();
        props.search(0, 5);
      } else {
        setFocus('client.name');
      }

      break;
    case 201:
      setFocus('client.name');
      showMessage(
        response.data.message,
        miscUtils.SEVERITY_SUCESS,
      );
      setTimeout(() => {
        setAlertMessage('');
        setOpenAlertMessage(false);
      }, 10000);
      break;
    case 400:
      if (_.lowerCase(response.data.message) === 'cliente ja existe') {
        setFocus('client.name');
        setError('client.cpf', {
          type: 'custom', message: 'Esse CPF já está cadastrado.',
        });
        setTimeout(() => {
          setFocus('client.cpf');
        }, 1);
      }
      if (_.lowerCase(response.data.message)
          === 'ja existe um cliente com esse cpf') {
        setFocus('client.name');
        setError('client.cpf', {
          type: 'custom', message: 'Esse CPF já está cadastrado.',
        });
        setTimeout(() => {
          setFocus('client.cpf');
        }, 1);
      }
      showMessage(
        response.data.message,
        miscUtils.SEVERITY_ERROR,
      );
      break;
    default:
      showMessage(
        `Ocorreu um erro inesperado: ${ response.data.message }`,
        miscUtils.SEVERITY_ERROR,
      );
      break;
    }
  }, [props, setError, setFocus]);

  const updateClient = useCallback((data) => {

    const {
      client,
      address,
      responsibles,
    } = data;
    
    const responsiblesData = [];

    for (const res of responsibles) {
      if (res.new && res.deleted) {
        return;
      } else {
        let data = {
          kinship: res.kinship,
          name: res.name,
          surname: res.surname,
          cpf: res.cpf,
          profession: res.profession,
          isFinancial: res.isFinancial,
          birthDate: res.birthDate,
          ClientId: props.clientId,
        };
  
        if (!res.new && !res.deleted) {
          data['_id'] = res._id;
        } else if (!res.new && res.deleted) {
          data = {
            _id: res._id,
            deleted: res.deleted,
          };
        }
  
        responsiblesData.push(data);
      }
    }

    validateFields(client, responsiblesData, showMessage, setFocus, setError,
      (validatedClient) => {
        const payload = {
          client: validatedClient,
          address,
          responsibles: responsiblesData,
        };
      
        setOpenBackDrop(true);
        clientService
          .updateClientById(props.clientId, payload)
          .then(response => {
            handleServiceResponse(response);
          })
          .catch(axiosError => {
            const response = axiosError.response;
            handleServiceResponse(response);
          });
      });

  }, [handleServiceResponse, props.clientId, setError, setFocus]);

  const register = useCallback((data) => {
    
    const {
      client,
      address,
      responsibles,
    } = data;
    
    const responsiblesData = [];
    for (const res of responsibles) {
      if (!res.deleted) {
        responsiblesData.push({
          kinship: res.kinship,
          name: res.name,
          surname: res.surname,
          cpf: res.cpf,
          profession: res.profession,
          isFinancial: res.isFinancial,
          birthDate: res.birthDate,
        });
      }
    }
    
    validateFields(client, responsiblesData, showMessage, setFocus, setError,
      (validatedClient) => {
        setOpenBackDrop(true);
      
        const payload = {
          client: validatedClient,
          address,
          responsibles: responsiblesData,
        };
        
        clientService
          .register(payload)
          .then(response => {
            handleServiceResponse(response);
            reset();
          })
          .catch(axiosError => {
            const response = axiosError.response;
            handleServiceResponse(response);
          });
      });
  }, [handleServiceResponse, reset, setError, setFocus]);

  const onSubmit = useCallback((data) => {
    if (props.actionName !== 'register') {
      updateClient(data);
    } else {
      register(data);
    }
  }, [props.actionName, updateClient, register]);

  const onEnter = useCallback(() => {
    trigger().then((isValid) => {
      if (isValid) {
        const data = getValues();
        onSubmit(data);
      }
    });
  }, [getValues, onSubmit, trigger]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onEnter();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onEnter]);

  const getMedicineReasonVisibility = () => {
    if (!isViewMode) {
      if (useMedicine) {
        return '';
      } else {
        return 'none';
      }
    } else {
      return '';
    }
  };

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      style={ {
        display: 'flex', justifyContent: 'center',
        overflowY: 'auto', overflowX: 'hidden', flexGrow: 1,
      } }
    >
      <Grid
        container
        spacing={ 1 }
        rowSpacing={ 1 }
        justifyContent="center"
        direction="row"
        sx={ { px: 10, pb: 10, pt: 5, maxWidth: '1000px' } }
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={openBackDrop}
        />
        <Grid item xs={ 12 } pt={ 10 } sx={ {
          display: openAlertMessage ? '' : 'none',
        } }>
          <Alert
            severity={ severity }
            sx={{ display: openAlertMessage ? '' : 'none', flexGrow:1 }}
          >
            { alertMessage }
          </Alert>
        </Grid>
        <Grid item xs={ 12 } pt={5} pb={2}>
          <Typography variant="h4" fontWeight={500}>
            {
              props.actionName !== 'register'
                ? 'Cliente' : 'Cadastro de Novo Cliente'
            }
          </Typography>
        </Grid>
        <ClientFields
          control={ control }
          isViewMode={ isViewMode }
        />
        <ClientResponsiblesList
          control={ control }
          isViewMode={ isViewMode }
          list={ responsibleFields }
          handleRemoveResponsible={ handleRemoveResponsible }
          handleAddResponsible={ handleAddResponsible }
          resetCheckboxes={ resetCheckboxes }
        />
        <Grid item sm={ 12 } xs={ 12 }>
          <FormControlLabel
            id="medicineCheckbox"
            control={<Checkbox disabled={isViewMode} />}
            value={ useMedicine }
            onChange={() => {
              setUseMedicine(!useMedicine); 
            } }
            label="Usa medicamentos?"
            className='checkbox-reverse'
          />
        </Grid>
        <Grid item xs={ 12 }
          sx={ {
            display: getMedicineReasonVisibility(),
          } }>
          <MultilineTextFieldController
            id="client-medicineReason-textfield"
            name="client.medicineReason"
            label="Lista de medicamentos e motivo de uso"
            control={ control }
            rows={ 4 }
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={ 12 } pb={ 5 }>
          <MultilineTextFieldController
            id="client-therapyReason-textfield"
            name="client.therapyReason"
            label="Motivo de procura da terapia"
            control={ control }
            rows={ 4 }
            disabled={isViewMode}
          />
        </Grid>
        <ClientAddress
          control={ control }
          isViewMode={ isViewMode }
        />
        <ClientFinancialFields
          control={ control }
          isViewMode={ isViewMode }
        />
        <Grid item xs={ 12 } pb={ 5 } sx={{
          display: isViewMode ? 'none' : 'grid',
          justifyContent: 'flex-end',
        }}>
          <Button
            type="submit"
            id="btnsubmit"
            size="large"
            variant="contained"
            startIcon={ <SaveIcon /> }
            disabled={isViewMode}
          >
            { props.actionName === 'register' ? 'Cadastrar' : 'Salvar' }
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ClientDialog;