/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import _ from 'lodash';

import {
  FormControlLabel,
  Checkbox,
  Box,
  Button,
} from '@mui/material';

import {
  Controller,
} from 'react-hook-form';

import '../../assets/css/WeekDayStyle.css';

function WeekDaysCheckboxController(props) {

  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  
  const {
    name,
    control,
    label,
    rules,
    id,
    disabled,
    resetCheckboxes,
    defaultValue,
    setValue,
  } = props;

  useEffect(() => {
    let days = '';
    
    if (sunday) {
      days = days.concat('SU,');
    }
    
    if (monday) {
      days = days.concat('MO,');
    }
    
    if (tuesday) {
      days = days.concat('TU,');
    }
    
    if (wednesday) {
      days = days.concat('WE,');
    }
    
    if (thursday) {
      days = days.concat('TH,');
    }
    
    if (friday) {
      days = days.concat('FR,');
    }
    
    if (saturday) {
      days = days.concat('SA,');
    }
    
    const value = days.length > 0 ? days.slice(0, days.length - 1) : '';

    setValue(name, value);
  }, [friday, monday, name, saturday, setValue,
    sunday, thursday, tuesday, wednesday]);

  useEffect(() => {
    if (!_.isNil(defaultValue)) {
      const days = defaultValue.split(',');

      days.forEach((value) => {
        switch (value) {
        case 'MO':
          setMonday(true);
          break;
        case 'TU':
          setTuesday(true);
          break;
        case 'WE':
          setWednesday(true);
          break;
        case 'TH':
          setThursday(true);
          break;
        case 'FR':
          setFriday(true);
          break;
        case 'SA':
          setSaturday(true);
          break;
        case 'SU':
          setSunday(true);
          break;
        default:
          break;
        }
      });
    }
  }, [defaultValue, props]);

  const verifyCheckboxes = (currentValue) => {
    let count = 0;
    if (monday) {
      count++;
    }
    if (tuesday) {
      count++;
    }
    if (wednesday) {
      count++;
    }
    if (thursday) {
      count++;
    }
    if (friday) {
      count++;
    }
    if (saturday) {
      count++;
    }
    if (sunday) { 
      count++;
    }
    return (currentValue === true && count > 1) || currentValue === false;
  };

  return (
    <Controller
      name={ name }
      control={ control }
      rules={ rules || undefined }
      render={ () => (
        <Box sx={ { display: 'inline-flex', pt: 1, pb: 2 } }>
          <Box
            component='span'
            className={ `weekday ${ monday ? 'checked' : '' }` }
            role='checkbox'
            onClick={ () => {
              if (verifyCheckboxes(monday)) {
                setMonday((prev) => !prev);
              }
            } }
            disableElevation>
            S
          </Box>
          <Box
            component='span'
            className={ `weekday ${ tuesday ? 'checked' : '' }` }
            role='checkbox'
            onClick={ () => {
              if (verifyCheckboxes(tuesday)) {
                setTuesday((prev) => !prev);
              }
            } }
            disableElevation>
            T
          </Box>
          <Box
            component='span'
            className={ `weekday ${ wednesday ? 'checked' : '' }` }
            role='checkbox'
            onClick={ () => {
              if (verifyCheckboxes(wednesday)) {
                setWednesday((prev) => !prev);
              }
            } }
            disableElevation>
            Q
          </Box>
          <Box
            component='span'
            className={ `weekday ${ thursday ? 'checked' : '' }` }
            role='checkbox'
            onClick={ () => {
              if (verifyCheckboxes(thursday)) {
                setThursday((prev) => !prev);
              }
            } }
            disableElevation>
            Q
          </Box>
          <Box
            component='span'
            className={ `weekday ${ friday ? 'checked' : '' }` }
            role='checkbox'
            onClick={ () => {
              if (verifyCheckboxes(friday)) {
                setFriday((prev) => !prev);
              }
            } }
            disableElevation>
            S
          </Box>
          <Box
            component='span'
            className={ `weekday ${ saturday ? 'checked' : '' }` }
            role='checkbox'
            onClick={ () => {
              if (verifyCheckboxes(saturday)) {
                setSaturday((prev) => !prev);
              }
            } }
            disableElevation>
            S
          </Box>
          <Box
            component='span'
            className={ `weekday ${ sunday ? 'checked' : '' }` }
            role='checkbox'
            onClick={ () => {
              if (verifyCheckboxes(sunday)) {
                setSunday((prev) => !prev);
              }
            } }
            disableElevation>
            D
          </Box>
        </Box>
      )}
    />
  );
}

export default WeekDaysCheckboxController;