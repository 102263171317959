import React from 'react';

import {
  Grid, Button, Typography,
} from '@mui/material';

import {
  AddCircleRounded as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';

import {
  TextFieldController,
  InputMaskTextFieldController,
} from '../../../components/TextField';

import DropdownController from
  '../../../components/Dropdown/DropdownController';

import DatePickerController from
  '../../../components/DatePicker/DatePickerController';

import CheckboxController from
  '../../../components/Checkbox/CheckboxController';

import getKinship from '../../../utils/getKinship';
const kinships = getKinship();

function ClientResponsiblesList({
  control,
  isViewMode,
  handleRemoveResponsible,
  handleAddResponsible,
  list,
  resetCheckboxes,
}) {

  const getResponsiblesVisibility = () => {
    const isViewModeAndListIsEmpty = isViewMode && list.length === 0;

    if (!isViewMode) {
      return '';
    } else {
      if (isViewModeAndListIsEmpty) {
        return 'none';
      } else {
        return '';
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        marginLeft={1}
        marginTop={ 2 }
        marginBottom={2}
        padding={ 2 }
        style={ {
          display: getResponsiblesVisibility(),
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.12)',
          borderWidth: 1,
          borderRadius: 8,
        }}
      >
        <Grid item xs={ 12 }
          sx={ {
            paddingTop: '0px !important',
            paddingBottom: 4,
          } }>
          <Typography variant="h6" fontWeight={500}>
          Responsáveis
          </Typography>
        </Grid>
        {
          list.map((responsible, index) => {
            const responsibleID = responsible.id;
            if (responsible.deleted === true) {
              return null;
            }
    
            return (
              <React.Fragment key={ index }>
                <Grid item sm={ 6 } xs={ 12 }>
                  <DropdownController
                    id={ `responsible-${ responsibleID }-kinship-dropdown` }
                    name={ `responsibles[${ index }].kinship` }
                    label="Parentesco *"
                    defaultHelperTextMessage="Obrigatório"
                    control={ control }
                    options={ kinships }
                    disabled={ isViewMode }
                  />
                </Grid>
                <Grid item sm={ 6 } xs={ 12 }>
                  <DatePickerController
                    id={ `responsible-${ responsibleID }-birthdate-datepicker` }
                    name={ `responsibles[${ index }].birthDate` }
                    label="Data de Nascimento"
                    max={new Date()}
                    control={ control }
                    disabled={ isViewMode }
                  />
                </Grid>
                <Grid item sm={ 6 } xs={ 12 }>
                  <TextFieldController
                    id={ `responsible-${ responsibleID }-name-textfield` }
                    name={ `responsibles[${ index }].name` }
                    label="Nome *"
                    control={ control }
                    defaultHelperTextMessage="Obrigatório"
                    disabled={ isViewMode }
                  />
                </Grid>
                <Grid item sm={ 6 } xs={ 12 }>
                  <TextFieldController
                    id={ `responsible-${ responsibleID }-surname-textfield` }
                    name={ `responsibles[${ index }].surname` }
                    label="Sobrenome *"
                    control={ control }
                    defaultHelperTextMessage="Obrigatório"
                    disabled={ isViewMode }
                  />
                </Grid>
                <Grid item sm={ 6 } xs={ 12 }>
                  <InputMaskTextFieldController
                    id={ `responsible-${ 
                      responsibleID
                    }-cpf-inputmasktextfield` }
                    name={ `responsibles[${ index }].cpf` }
                    label="CPF *"
                    mask={ '999.999.999-99' }
                    control={ control }
                    defaultHelperTextMessage="Obrigatório"
                    disabled={ isViewMode }
                  />
                </Grid>
                <Grid item sm={ 6 } xs={ 12 }>
                  <TextFieldController
                    id={ `responsible-${ responsibleID }-profession-textfield` }
                    name={ `responsibles[${ index }].profession` }
                    label="Profissão"
                    control={ control }
                    defaultHelperTextMessage=""
                    disabled={ isViewMode }
                  />
                </Grid>
                <Grid item xs={ 12 }
                  sx={ {
                    paddingBottom: '40px !important',
                  } }>
                  <CheckboxController
                    id={ `responsible-${ responsibleID }-isFinancial-checkbox` }
                    name={ `responsibles[${ index }].isFinancial` }
                    label="Responsável Financeiro"
                    control={ control }
                    disabled={ isViewMode }
                    resetCheckboxes={ resetCheckboxes }
                  />
                </Grid>
                <Grid item xs={ 12 } sx={ {
                  display: isViewMode ? 'none' : 'grid',
                  justifyContent: 'flex-end',
                  alignContent: 'center',
                  paddingBottom: '40px !important',
                } }>
                  <Button
                    id="btnRemoveResponsible"
                    size="small"
                    variant="contained"
                    onClick={ () => {
                      handleRemoveResponsible(index); 
                    } }
                    color="error"
                    style={ { height: '28px' } }
                    startIcon={ <RemoveIcon /> }
                    disabled={ isViewMode }
                  >
                  Remover Responsável
                  </Button>
                </Grid>
              </React.Fragment>
            );
          })
        }
        <Grid item sm={ 12 } xs={ 12 } sx={ {
          display: isViewMode ? 'none' : 'grid',
          justifyContent: 'flex-end',
        } }>
          <Button
            id=""
            size="small"
            variant="contained"
            onClick={handleAddResponsible}
            startIcon={ <AddIcon /> }
            disabled={isViewMode}
          >
          Adicionar Novo Responsável
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default ClientResponsiblesList;