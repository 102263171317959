import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Button, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ClientDialog from '../Client/ClientDialog';

// datepicker
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers';

// radio
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// form
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';

// checkbox
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';

// select
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Dialog from '@mui/material/Dialog';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import Backdrop from '@mui/material/Backdrop';

import { formatISO, addHours, toDate, addMonths, isAfter } from 'date-fns';
import _ from 'lodash';
// css
import '../../assets/css/Wrappers.css';

const miscUtils = require('../../utils/MiscellaneousUtils');
const scheduleService = require('../../service/ScheduleService');
const clientService = require('../../service/ClientService');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewSchedule(props) {
  // #consts
  const [selectedClient, setSelectedClient] = useState(null);
  const [medicalConsultationFee, setmedicalConsultationFee] = useState(0);
  const [weeksBetween, setWeeksBetween] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endsWhen, setendsWhen] = useState(new Date());
  const [recurrenceStart] = useState(
    new Date(props.scheduleStartDate),
  );
  const [price, setPrice] = useState('f');
  const [whenItEnds, setWhenItEnds] = useState(1);
  const [recurrence, setRecurrence] = useState(false);
  const [sessionsNumber, setSessionsNumber] = React.useState(1);
  const [sunday, setSunday] = React.useState(false);
  const [monday, setMonday] = React.useState(false);
  const [tuesday, setTuesday] = React.useState(false);
  const [wednesday, setWednesday] = React.useState(false);
  const [thursday, setThurday] = React.useState(false);
  const [friday, setFriday] = React.useState(false);
  const [saturday, setSaturday] = React.useState(false);
  const [isOpen, setOpen] = useState(false);
  const [clients, setCLients] = useState([]);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [severity, setSeverity] = useState(Alert.SEVERITY_ERROR);
  const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isVideoConferencing, setIsVideoConferencing] = useState('false');
  const [videoTextLink, setVideoTextLink] = useState('');

  const getDaysOfWeek = useCallback(() => {
    let days = '';
    if (sunday) {
      days = days.concat('SU,');
    }
    if (monday) {
      days = days.concat('MO,');
    }
    if (tuesday) {
      days = days.concat('TU,');
    }
    if (wednesday) {
      days = days.concat('WE,');
    }
    if (thursday) {
      days = days.concat('TH,');
    }
    if (friday) {
      days = days.concat('FR,');
    }
    if (saturday) {
      days = days.concat('SA,');
    }
    return days.length > 0 ? days.slice(0, days.length - 1) : '';
  }, [friday, monday, saturday, sunday, thursday, tuesday, wednesday]);

  const getRecurring = useCallback(() => {
    const recur = {};
    if (recurrence) {
      recur.repeat = 'weekly';
      recur.weekDays = getDaysOfWeek();
      recur.interval = weeksBetween;
      switch (whenItEnds) {
      case 1:
        break;

      case 2:
        recur.count = parseInt(sessionsNumber, 10);
        break;

      case 3:
        recur.until = endsWhen;
        break;

      default:
        break;
      }
    }

    // set limit to 6 months
    if (recurrence && _.isNil(recur.until) && _.isNil(recur.count)) {
      const initialDate = toDate(startDate);
      const nextSixMonths = addMonths(initialDate, 6);
      recur.until = formatISO(nextSixMonths);
    }

    return recur;
  }, [endsWhen, getDaysOfWeek, recurrence, sessionsNumber,
    startDate, weeksBetween, whenItEnds]);

  // #func

  const toSchedule = useCallback(() => {
    if (!selectedClient) {
      showMessage('Selecione o cliente', miscUtils.SEVERITY_ERROR);
    } else if (!startDate) {
      showMessage(
        'Informe a hora do início da consulta',
        miscUtils.SEVERITY_ERROR,
      );
    } else if (medicalConsultationFee === '') {
      showMessage('Informe o valor da consulta', miscUtils.SEVERITY_ERROR);
    } else {
      /*
       * const tmp = {
       *   start: "2022-08-14T10:00",
       *   end: "2022-08-14T10:45",
       *   title: "Lunch @ Butcher's",
       *   description: "",
       *   allDay: false,
       *   free: true,
       *   color: "#001388",
       *   recurring: {
       *     repeat: "weekly",
       *     weekDays: "SU,MO,TU",
       *     interval: 1
       *   }
       * }
       */

      const recurring = getRecurring();
      if (recurrence && recurring.weekDays === '') {
        showMessage(
          'Selecione pelo menos um dia da semana',
          miscUtils.SEVERITY_ERROR,
        );
      } else {
        const schedule = {
          selectedClient: selectedClient,
          medicalConsultationFee: medicalConsultationFee,
          start: formatISO(startDate),
          title: `${ selectedClient.label }`,
          color: '#' + Math.floor(Math.random() * 16777215).toString(16),
          end: formatISO(endDate),
          price: price,
          recurring: recurring,
          sessionsNumber: sessionsNumber,
          isVideoConferencing: isVideoConferencing === 'true',
        };

        if (isVideoConferencing === 'true') {
          schedule['videoConferencingLink'] = videoTextLink;
        }
        
        setOpenBackDrop(true);
        scheduleService
          .register(schedule)
          .then(e => {
            if (e.data.errors) {
              // console.log(e.data.errors.start);
              if (e.data.errors.start) {
                showMessage(
                  'A data de início não pode ser menor que a data e hora atual',
                  miscUtils.SEVERITY_ERROR,
                );
              } else {
                showMessage('Ocorreu um erro dasd', miscUtils.SEVERITY_ERROR);
                console.error(e.data.errors);
              }
            } else {
              showMessage(
                'Agendamento criado com sucesso',
                miscUtils.SEVERITY_SUCESS,
              );
              props.handleClose();
            }
          })
          .catch(() => {
            showMessage(
              'Aconteceu um erro inesperado',
              miscUtils.SEVERITY_ERROR,
            );
            // console.log(e);
          })
          .finally(() => {
            handleCloseBackDrop();
          });
      }
    }
  }, [endDate, getRecurring, isVideoConferencing, medicalConsultationFee,
    price, props, recurrence, selectedClient,
    sessionsNumber, startDate, videoTextLink]);

  const handleSubmit = useCallback(() => {
    toSchedule();
  }, [toSchedule]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleSubmit]);

  const handleCloseThisDialog = () => {
    props.handleClose();
  };

  const handleWeeksBetween = newValue => {
    if (newValue >= 1) {
      setWeeksBetween(newValue);
    }
  };

  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };

  const showMessage = function (message, severity) {
    setSeverity(severity);
    setAlertMessage(message);
    setOpenAlertMessage(true);
    setTimeout(() => {
      setOpenAlertMessage(false);
    }, 5000);
  };

  const getCLients = useCallback(() => {
    clientService
      .getByUserAutocomplete()
      .then(response => {
        setCLients(response.data);
      })
      .catch(() => {
        // console.log(error);
      })
      .finally(() => {
        handleCloseBackDrop();
      });
  }, []);

  useEffect(() => {
    getCLients();
    if (_.isNil(startDate) && _.isNil(endDate)) {
      const initialDate = toDate(props.scheduleStartDate);
      setStartDate(initialDate);
      const finalDate = addHours(initialDate, 1);
      setEndDate(finalDate);
    }
  }, [endDate, getCLients, props.scheduleStartDate, startDate]);

  const handleClose = () => {
    setOpen(false);
    // console.log("fechou o cadastro do cliente");
    getCLients();
  };

  const handleStartDate = newValue => {
    const initialDate = toDate(newValue);
    setStartDate(initialDate);
    const year = initialDate.getFullYear();
    const month = initialDate.getMonth();
    const day = initialDate.getDate();

    const finalDate = toDate(endDate);
    finalDate.setFullYear(year);
    finalDate.setMonth(month);
    finalDate.setDate(day);

    setEndDate(finalDate);
  };

  const handleChangeStartTime = newValue => {
    const initialDate = toDate(newValue);
    setStartDate(initialDate);

    const hours = initialDate.getHours();
    const minutes = initialDate.getMinutes();
    const seconds = initialDate.getSeconds();

    const finalDate = toDate(initialDate);
    finalDate.setHours(hours);
    finalDate.setMinutes(minutes);
    finalDate.setSeconds(seconds);

    const newEndDate = addHours(finalDate, 1);

    setEndDate(newEndDate);
  };

  const handleChangeEndDate = newValue => {
    setEndDate(newValue);
  };

  const handleChangeEndTime = newValue => {
    const finalDate = toDate(newValue);
    if (isAfter(finalDate, startDate)) {
      setEndDate(finalDate);
    }
  };

  const handleEndsWhen = newValue => {
    setendsWhen(newValue);
  };

  const handleStart = newStart => {
    recurrenceStart(newStart);
  };

  const handlePrice = event => {
    setPrice(event.target.value);
  };

  const handleChangeLocation = event => {
    setIsVideoConferencing(event.target.value);
  };

  const onChangeRecurrence = () => {
    setRecurrence(!recurrence);
    if (!recurrence) {
      setWhenItEnds('');
    }
  };

  const handleEnd = event => {
    setWhenItEnds(event.target.value);
  };

  const onChangeMedicalConsultationFee = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setmedicalConsultationFee(value);
  };

  return (
    <div className="wrapper-fullscreen">
      <Grid
        paddingTop={ 4 }
        px={5}
        justifyContent="start"
        spacing={ 0 }
        rowSpacing={ 0 }
        container>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={openBackDrop}
        />
        <Grid paddingBottom={ 1.5 } item xs={ 11 }>
          <Alert
            severity={severity}
            sx={{ mx: 0.5, display: openAlertMessage ? '' : 'none' }}
          >
            {alertMessage}
          </Alert>
        </Grid>
        <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, fontSize: '1.375rem;' }}        
                variant="subtitle1">
                Cliente
              </Typography>
            </Toolbar>
          </AppBar>
          <ClientDialog
            handleCloseDialog={ handleClose }
            actionName="register" />
        </Dialog>
        <Grid
          container
          spacing={1}
          rowSpacing={2}
        >
          <Grid item xs={8}>
            <Autocomplete
              disablePortal
              value={selectedClient}
              onChange={(event, value) => {
                setSelectedClient(value);
              }}
              id="combo-box-demo"
              options={clients}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              renderInput={params => <TextField {...params} label="Cliente" />}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => setOpen(true)}
            >
              Novo Cliente
            </Button>
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              views={['day', 'month', 'year']}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              mask={'__/__/____'}
              label="Data Inicial"
              value={startDate}
              onChange={handleStartDate}
              sx={{ width: '100%' }}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={2}>
            <TimePicker
              sx={{ width: '100%' }}
              label="Hora Inicial"
              value={startDate}
              onChange={handleChangeStartTime}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={2}>
            <TimePicker
              sx={{ width: '100%' }}
              label="Hora Final"
              value={ endDate }
              onChange={ handleChangeEndTime }
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              views={['day', 'month', 'year']}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              mask={'__/__/____'}
              label="Data Final"
              value={ endDate }
              onChange={ handleChangeEndDate }
              sx={{ width: '100%' }}
              renderInput={ params => <TextField { ...params } /> }
              disabled={ true }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="consultation-fee-textfield"
              label="Valor"
              value={medicalConsultationFee}
              onChange={onChangeMedicalConsultationFee}
              variant="outlined"
              InputProps={ {
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Regra Cobrança
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={price}
                onChange={handlePrice}
              >
                <FormControlLabel
                  value="f"
                  control={<Radio />}
                  label="Mês Fechado"
                />
                <FormControlLabel
                  value="s"
                  control={<Radio />}
                  label="Por Sessão"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={ 6 }>
            <FormControl>
              <FormLabel
                id="locale-schedule-label">
                Local
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="locale-schedule-label"
                name="locale-schedule-group"
                value={ isVideoConferencing }
                onChange={ handleChangeLocation }
              >
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label="Presencial"
                />
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label="Videoconferência"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={ 12 } style={{
            display: isVideoConferencing === 'true' ? 'block' : 'none',
          }}>
            <TextField
              id="link-textfield"
              label="Link da Videoconferência"
              value={videoTextLink}
              onChange={e => {
                setVideoTextLink(e.target.value);
              }}
              variant="outlined"
              sx={{
                width: 300,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ recurrence }
                    onChange={ onChangeRecurrence }
                  />
                }
                label="Recorrência?"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          rowSpacing={2}
          sx={{ margin: '5px' }}
          style={recurrence ? {} : { display: 'none' }}
        >
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="A cada quantas semanas?"
              variant="outlined"
              value={weeksBetween}
              onChange={e => {
                handleWeeksBetween(e.target.value);
              }}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={10}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Em quais dias da semana?</FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={monday}
                      onChange={e => {
                        setMonday(e.target.checked);
                      }}
                    />
                  }
                  label="Segunda Feira"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={tuesday}
                      onChange={e => {
                        setTuesday(e.target.checked);
                      }}
                    />
                  }
                  label="Terça Feira"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={wednesday}
                      onChange={e => {
                        setWednesday(e.target.checked);
                      }}
                    />
                  }
                  label="Quarta Feira"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={thursday}
                      onChange={e => {
                        setThurday(e.target.checked);
                      }}
                    />
                  }
                  label="Quinta Feira"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={friday}
                      onChange={e => {
                        setFriday(e.target.checked);
                      }}
                    />
                  }
                  label="Sexta Feira"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={saturday}
                      onChange={e => {
                        setSaturday(e.target.checked);
                      }}
                    />
                  }
                  label="Sábado"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={sunday}
                      onChange={e => {
                        setSunday(e.target.checked);
                      }}
                    />
                  }
                  label="Domingo"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              views={['day', 'month', 'year']}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              mask={'__/__/____'}
              label="Começa em"
              value={recurrenceStart}
              onChange={handleStart}
              sx={{ width: '100%' }}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Quando finaliza?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={whenItEnds}
                defaultValue={1}
                label="Quando finaliza?"
                onChange={handleEnd}
              >
                <MenuItem value={1}>Sem data de término</MenuItem>
                <MenuItem value={2}>Termina após...</MenuItem>
                <MenuItem value={3}>Termina em...</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} style={whenItEnds === 3 ? {} : { display: 'none' }}>
            <DatePicker
              label="Termina no dia"
              views={['day', 'month', 'year']}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              mask={'__/__/____'}
              value={endsWhen}
              onChange={handleEndsWhen}
              sx={{ width: '100%' }}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={6} style={whenItEnds === 2 ? {} : { display: 'none' }}>
            <TextField
              id="txtSessions"
              label="Número de sessões"
              value={sessionsNumber}
              onChange={e => {
                setSessionsNumber(e.target.value);
              }}
              variant="outlined"
              fullWidth
              type="number"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={ 1 }
          rowSpacing={ 2 }
          sx={ { margin: '5px' } }
        >
          <Grid item xs={2}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={toSchedule}
              startIcon={<SaveIcon />}
            >
              Agendar
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="error"
              onClick={handleCloseThisDialog}
              startIcon={<CancelIcon />}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
