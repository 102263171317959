import React from 'react';

import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import Container from './Container';

import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import _ from 'lodash';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function ProtectedLayout() {

  const { auth } = useAuth();
  if (_.isNil(auth) && _.isNil(cookies.get('token'))) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  
  return (
    <>
      <Navbar />
      <Container>
        <Outlet />
      </Container>
    </>    
  );
}