import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { Controller } from 'react-hook-form';

function EditTextFieldController(props) {

  const {
    name,
    control,
    rules,
    id,
    defaultHelperTextMessage,
    disabled,
    resetField,
    saveField,
    isNewSessionRecord,
  } = props;

  const [isEditable, setIsEditable] = useState(isNewSessionRecord);

  const getTextWithLineBreaks = (value) => {
    if (value) {
      const arrayOfParagraphs = value.split('\n');
      const result = arrayOfParagraphs.filter((text) => text.length !== 0);
      return result;
    } else {
      return [''];
    }
  };

  return (
    <Controller
      name={ name }
      control={ control }
      rules={ rules || undefined }
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <>
          {
            isEditable
              ?
              <Box>
                <TextField
                  value={ value }
                  onChange={ onChange }
                  autoFocus
                  onBlur={ onBlur }
                  inputRef={ ref }
                  label={ '' }
                  id={ id }
                  fullWidth
                  variant="outlined"
                  helperText={ error?.message || defaultHelperTextMessage }
                  error={ invalid }
                  multiline
                  disabled={ disabled }
                  sx={ {
                    textAlign: 'justify',
                  } }
                />
                <Box pt={ 1 }>
                  <Button
                    id="save-button"
                    size="medium"
                    variant="contained"
                    disabled={ false }
                    sx={ { marginRight: '8px' } }
                    onClick={ () => {
                      saveField(value);
                      setIsEditable(false);
                    } }
                  >
                    <Typography
                      textTransform="capitalize"
                      fontSize="0.85rem"
                      fontWeight={ 600 }>
                        Salvar
                    </Typography>
                  </Button>
                  <Button
                    id="cancel-button"
                    size="medium"
                    variant="text"
                    disabled={ false }
                    pr={ 2 }
                    onClick={ () => {
                      // reset field to defaulValue
                      resetField();
                      setIsEditable(false);
                    } }
                  >
                    <Typography
                      textTransform="capitalize"
                      fontSize="0.85rem"
                      fontWeight={ 600 }>
                        Cancelar
                    </Typography>
                  </Button>
                </Box>
              </Box>
              :
              <>
                <Box
                  component="div"
                  display="block"
                  onClick={ () => {
                    setIsEditable(true);
                  } }
                >
                  { getTextWithLineBreaks(value).map((paragraph, index) => {
                    return (
                      <Typography
                        key={ index }
                        sx={ {
                          display: 'block',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                          fontSize: '1rem',
                          textAlign: 'justify',
                        } }
                        paragraph={ true }
                        variant="body2"
                        color="text.primary"
                      >
                        { paragraph }
                      </Typography>
                    );
                  }) }
                </Box>
                <Button
                  id="edit-button"
                  size="medium"
                  variant="text"
                  disabled={ false }
                  pr={ 2 }
                  onClick={ () => {
                    setIsEditable(true);
                  } }
                  sx={ {
                    bgcolor: 'rgba(9, 30, 66, 0.04)',
                    color: '#000',
                  } }
                >
                  <Typography
                    fontSize="0.75rem"
                    fontWeight={ 600 }
                    sx={ {
                      textDecoration: 'none',
                      textTransform: 'capitalize',
                      letterSpacing: 1,
                    } }>
                    Editar
                  </Typography>
                </Button>
              </>
          }
        </>
      ) }
    />
  );
}

export default EditTextFieldController;