import React, { useEffect, useCallback, useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import {
  TextFieldController,
} from '../../components/TextField';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
const userService = require('../../service/UserService');

const schema = z
  .object({
    email: z.string().email('E-mail inválido.')
      .min(1, 'Preencha o campo e-mail.'),
  });

const defaultValues = {
  email: '',
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Todos os direitos reservados © '}
      <Link color="inherit" href="https://www.cafecomjava.com.br/">
        Café com Java
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function ForgotPassword(props) {
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  // eslint-disable-next-line no-unused-vars

  const navigate = useNavigate();
  const query = useQuery();

  const {
    control,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const handleToggle = useCallback(() => {
    setOpenBackDrop(!openBackDrop);
  }, [openBackDrop]);

  const sendEmailToRecoverPassword = (data) => {
    const { email } = data;
    handleToggle();
    userService
      .recoverPassword(email)
      .finally(() => {
        handleClose();
      });
  };

  const handleClose = () => {
    setOpenBackDrop(false);
  };

  useEffect(() => {
    if (!_.isNil(query.get('token')) && !_.isEmpty(query.get('token'))
      && !_.isNil(query.get('email')) && !_.isEmpty(query.get('email'))) {
      userService
        .verifyRecoverToken(query.get('token'))
        .then((response) => {
          if (response.status === 200) {
            navigate('/resetPassword', {
              state: {
                token: query.get('token'),
                email: query.get('email'),
              },
            });
          } else {
            window.location = '/';
          }
        })
        .catch((err) => {
          window.location = '/';
        });
    }
  }, [query, navigate, props]);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="xs" sx={ {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar senha
          </Typography>
          <Box
            onSubmit={handleSubmit(sendEmailToRecoverPassword)}
            component="form"
            autoComplete="off"
            sx={{ mt: 5, width: '100%' }}
          >
            <TextFieldController
              id='email-textfield'
              label='E-mail'
              name='email'
              control={control}
            />
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            />
            <Button
              type='submit'
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {' '}Recuperar
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
