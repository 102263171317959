import React, { useState } from 'react';

import {
  Button,
  Grid,
  Popper,
  Paper,
  Typography,
  ClickAwayListener,
  Fade,
  Slide,
  Box,
  Divider,
} from '@mui/material';

import _ from 'lodash';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import OndemandVideoOutlinedIcon from
  '@mui/icons-material/OndemandVideoOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AccountCircleOutlinedIcon from
  '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from 
  '@mui/icons-material/CalendarMonthOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import VideoCallIcon from '@mui/icons-material/VideoCall';

import * as scheduleService from '../../service/ScheduleService';
import ScheduleStatus from '../../utils/ScheduleStatus';

import { useNavigate } from 'react-router-dom';

import '../../assets/css/Tooltip.css';

export default function ScheduleToolTip(props) {

  const [
    tooltipClipboardText,
    setTooltipClipboardText,
  ] = useState('Copiar Link');
  const navigate = useNavigate();

  const startMedicalConsultation = () => {
    props.medicalConsultationStarted(props.currentEvent);
  };

  const goToSessionScreen = () => {
    const ID = props.currentEvent.ClientId._id;
    navigate(`/sessions/${ ID }`);
  };

  const openClientInfo = () => {
    props.openClientInfoDialog();
  };

  const openReSchedule = () => {
    props.openReScheduleDialog();
  };

  const changeScheduleStatusToAccomplished = async () => {
    if (!_.isNil(props.currentEvent) &&
      !_.isNil(props.currentEvent._id) &&
      !_.isEmpty(props.currentEvent._id)) {
      
      const scheduleId = props.currentEvent._id;
      const start = props.currentEvent.start;

      scheduleService
        .edit({
          id: scheduleId,
          status: ScheduleStatus.accomplished,
          startDate: start,
        });
    }
  };

  const copyVideoConferencingLink = () => {
    const link = props.currentEvent.videoConferencingLink;
    navigator.clipboard.writeText(link);
    setTooltipClipboardText('Link copiado');
    setTimeout(() => {
      setTooltipClipboardText('Copiar Link');
    }, 2000);
  };

  return (
    <Popper
      placement='left-start'
      open={ props.open }
      anchorEl={ props.anchorEl }
      sx={ {
        zIndex: 2,
        padding: 2,
      }}
    >
      <ClickAwayListener onClickAway={ props.closeToolTip }>
        <Slide
          direction="left"
          in={ props.open }
          timeout={150}
          container={ props.anchorEl }
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Fade in={props.open} timeout={500} unmountOnExit>
              <Paper elevation={24} sx={{ borderRadius: 2 }}>
                <Box width={ 300 } pb={2}>
                  <Grid
                    container
                    spacing={2}
                    rowSpacing={ 0 }
                    sx={{
                      marginLeft: 0,
                      width: '100%',
                    }}
                  >
                    <Grid item xs={12} paddingRight={2} pb={2}>
                      <Typography
                        fontSize={ 22 }
                        py={ 1 }
                        px={ 1 }
                        textAlign='center'
                        sx={ {
                          borderColor: 'rgba(0, 0, 0, 0.12)',
                          borderStyle: 'solid',
                          borderWidth: 1,
                          borderRadius: 1,
                          color: 'rgb(60,64,67)',
                          wordWrap: 'normal',
                        }}
                      >
                        {
                          props.currentEvent.ClientId.name + ' ' +
                  props.currentEvent.ClientId.surname
                        }
                      </Typography>
                    </Grid>
                    { props.currentEvent.ClientId.cellphone !== '' && (
                      <>
                        <Grid item xs={ 2 } textAlign='center'>
                          <WhatsAppIcon
                            sx={ {
                              color: 'rgb(95,99,104)',
                            } }
                            fontSize='small'
                          />
                        </Grid>
                        <Grid item xs={ 10 } sx={ {
                          paddingLeft: '4px !important;',
                        } }>
                          <Typography
                            fontSize={ 14 }
                            lineHeight={ 1.4 } sx={ {
                              color: 'rgb(60,64,67)',
                            } }
                          >
                            {
                              props.currentEvent.ClientId.cellphone.length > 0
                              &&
                      props.currentEvent.ClientId.cellphone.slice(0, 4) +
                      ' ' +
                      props.currentEvent.ClientId.cellphone.slice(
                        4, props.currentEvent.ClientId.cellphone.length,
                      )
                            }
                          </Typography>
                        </Grid>
                      </>
                    )}
                    { props.currentEvent.ClientId.email !== '' && (
                      <>
                        <Grid item xs={ 2 } textAlign='center'>
                          <EmailOutlinedIcon
                            sx={ {
                              color: 'rgb(95,99,104)',
                            } }
                            fontSize='small'
                          />
                        </Grid>
                        <Grid item xs={ 10 } sx={ {
                          paddingLeft: '4px !important;',
                        } }>
                          <Typography
                            fontSize={ 14 }
                            lineHeight={ 1.4 }
                            sx={{
                              textDecoration: 'underline',
                              color: 'rgb(60,64,67)',
                            }}
                          >
                            {
                              props.currentEvent.ClientId.email
                            }
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={ 12 } sx={ {
                      paddingLeft: '0px !important;',
                    } }>
                      <Divider
                        sx={ { height: 8 } }
                        orientation="horizontal"
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={ 2 } textAlign='center'>
                      <OndemandVideoOutlinedIcon
                        sx={ {
                          color: 'rgb(95,99,104)',
                        } }
                        fontSize='small'
                      />
                    </Grid>
                    <Grid item xs={ 10 } sx={ {
                      paddingLeft: '4px !important',
                      paddingRight: 2,
                    } }>
                      <Button
                        component={ Typography }
                        sx={ {
                          color: 'rgb(60,64,67)',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          width: '100%',
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          padding: 0,
                          lineHeight: 1.4,
                        } }
                        disableRipple
                        onClick={ () => {
                          changeScheduleStatusToAccomplished();
                          startMedicalConsultation();
                        }}
                      >
                        Iniciar Atendimento
                      </Button>
                    </Grid>
                    <Grid item xs={ 2 } textAlign='center'>
                      <AssignmentOutlinedIcon
                        sx={ {
                          color: 'rgb(95,99,104)',
                        } }
                        fontSize='small'
                      />
                    </Grid>
                    <Grid item xs={ 10 } sx={ {
                      paddingLeft: '4px !important',
                      paddingRight: 2,
                    } }>
                      <Button
                        component={ Typography }
                        sx={ {
                          color: 'rgb(60,64,67)',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          width: '100%',
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          padding: 0,
                          lineHeight: 1.4,
                        } }
                        disableRipple
                        onClick={ goToSessionScreen }
                      >
                        Abrir Prontuário
                      </Button>
                    </Grid>
                    <Grid item xs={ 2 } textAlign='center'>
                      <AccountCircleOutlinedIcon
                        sx={ {
                          color: 'rgb(95,99,104)',
                        } }
                        fontSize='small'
                      />
                    </Grid>
                    <Grid item xs={ 10 } sx={ {
                      paddingLeft: '4px !important',
                      paddingRight: 2,
                    } }>
                      <Button
                        component={ Typography }
                        sx={ {
                          color: 'rgb(60,64,67)',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          width: '100%',
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          padding: 0,
                          lineHeight: 1.4,
                        } }
                        disableRipple
                        onClick={ openClientInfo }
                      >
                        Informações do Cliente
                      </Button>
                    </Grid>
                    <Grid item xs={ 2 } textAlign='center'>
                      <CalendarMonthOutlinedIcon
                        sx={ {
                          color: 'rgb(95,99,104)',
                        } }
                        fontSize='small'
                      />
                    </Grid>
                    <Grid item xs={ 10 } sx={ {
                      paddingLeft: '4px !important',
                      paddingRight: 2,
                    } }>
                      <Button
                        component={ Typography }
                        sx={ {
                          color: 'rgb(60,64,67)',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          width: '100%',
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          padding: 0,
                          lineHeight: 1.4,
                        } }
                        disableRipple
                        onClick={ openReSchedule }
                      >
                        Re-agendar
                      </Button>
                    </Grid>
                    <Grid item xs={ 2 } textAlign='center'>
                      <VideoCallIcon
                        sx={ {
                          color: 'rgb(95,99,104)',
                        } }
                        fontSize='small'
                      />
                    </Grid>
                    <Grid item xs={ 10 } className='tooltip' sx={ {
                      paddingLeft: '4px !important',
                      paddingRight: 2,
                      display: props
                        .currentEvent.videoConferencingLink ? '' : 'none',
                    } }>
                      <Button
                        component={ Typography }
                        sx={ {
                          color: 'rgb(60,64,67)',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          width: '100%',
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          padding: 0,
                          lineHeight: 1.4,
                        } }
                        disableRipple
                        onClick={ copyVideoConferencingLink }
                      >
                        <span class="tooltiptext">
                          {tooltipClipboardText}
                        </span>
                        Videoconferência
                      </Button>
                    </Grid>
                    <Grid item xs={ 2 } textAlign='center'>
                      <EventBusyOutlinedIcon
                        sx={ {
                          color: 'rgb(95,99,104)',
                        } }
                        fontSize='small'
                      />
                    </Grid>
                    <Grid item xs={ 10 } sx={ {
                      paddingLeft: '4px !important',
                      paddingRight: 2,
                    } }>
                      <Button
                        component={ Typography }
                        sx={ {
                          color: 'rgb(60,64,67)',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          width: '100%',
                          justifyContent: 'flex-start',
                          textTransform: 'none',
                          padding: 0,
                          lineHeight: 1.4,
                        } }
                        disableRipple
                        onClick={ () => {} }
                      >
                        Cancelar Agendamento
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Fade>
          </div>
        </Slide>
      </ClickAwayListener>
    </Popper>
  );
}
