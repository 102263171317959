import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';

import { parseISO, format } from 'date-fns';

import EventNoteIcon from '@mui/icons-material/EventNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Dialog from '@mui/material/Dialog';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import ClientDialog from '../ClientDialog';
import InactivateClientDialog from './InactivateClientDialog';
import SessionRecord from '../../Calendar/SessionRecord';

import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (<Slide
    direction="up"
    ref={ ref }
    { ...props }
    unmountOnExit />);
});

function ClientsDataGrid(props) {

  const [actionName, setActionName] = useState('');
  const [clientId, setClientId] = useState('');
  const [isMedicalRecordOpen, setMedicalRecordOpen] = useState(false);
  const [isClientDialogOpen, setClientDialogOpen] = useState(false);
  const [
    isInactivateClientDialogOpen,
    setIsInactivateClientDialogOpen,
  ] = useState(false);

  const navigate = useNavigate();

  const handleMedicalConsultationClose = () => {
    setMedicalRecordOpen(false);
  };
  
  const renderSessionsButton = (params) => {
    const onClick = () => {
      setClientId(params.id);
      navigate(`/sessions/${ params.id }`);
    };

    return (
      <IconButton
        id="sessions-client-button"
        size="small"
        aria-label='sessions'
        onClick={onClick}
      >
        <EventNoteIcon />
      </IconButton>
    );
  };

  const renderViewButton = (params) => {
    const onClick = () => {
      setClientId(params.id);
      setActionName('view');
      setClientDialogOpen(true);
    };

    return (
      <IconButton
        id="view-client-button"
        size="small"
        aria-label='view'
        onClick={onClick}
      >
        <VisibilityIcon />
      </IconButton>
    );
  };

  const renderEditButton = (params) => {
    const onClick = () => {
      setClientId(params.id);
      setActionName('edit');
      setClientDialogOpen(true);
    };

    return (
      <IconButton
        id="edit-client-button"
        size="small"
        aria-label='edit'
        onClick={onClick}
      >
        <EditIcon />
      </IconButton>
    );
  };

  const renderDeleteButton = (params) => {
    const onClick = () => {
      setClientId(params.id);
      setIsInactivateClientDialogOpen(true);
    };

    return (
      <IconButton
        id="delete-client-button"
        size="small"
        aria-label='delete'
        onClick={onClick}
      >
        <HighlightOffIcon />
      </IconButton>
    );
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Nome',
      sortable: false,
      filterable: false,
      width: 300,
      valueGetter: (params) =>
        `${params.row.name || ''} ${params.row.surname || ''}`,
    },
    {
      field: 'gender',
      headerName: 'Sexo',
      width: 150,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        if (params.row.gender === 'M') {
          return 'Masculino';
        } else if (params.row.gender === 'F') {
          return 'Feminino';
        } else if (params.row.gender === '?') {
          return 'Não informado';
        }
      },
    },
    {
      field: 'birthDate',
      headerName: 'Nascimento',
      width: 130,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const date = parseISO(params.row.birthDate);
        return format(date, 'P');
      },
    },
    {
      field: 'cellphone',
      headerName: 'Celular',
      width: 175,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        const text = params.row.cellphone.slice(0, 4) + ' ' +
          params.row.cellphone.slice(4, params.row.cellphone.length);
        
        return `${ params.row.cellphone.length > 0 ? text : '' }`;
      },
    },
    {
      field: 'sessions',
      headerName: 'Sessões',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: renderSessionsButton,
    },
    {
      field: 'view',
      headerName: 'Visualizar',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: renderViewButton,
    },
    {
      field: 'edit',
      headerName: 'Editar',
      width: 65,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: renderEditButton,
    },
    {
      field: 'delete',
      headerName: 'Desativar',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: renderDeleteButton,
    },
  ];

  const handleCloseClientDialog = () => {
    setClientDialogOpen(false);
  };

  const handleCloseInactivateClientDialog = () => {
    setIsInactivateClientDialogOpen(false);
  };
  
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '8px',
          margin: '0px',
          width: '100%',
          minHeight: '200px',
          maxWidth: '1200px',
          alignSelf: 'center',
          flexGrow: 0.8,
        }}
      >
        <Grid
          item
          xs={ 12 }
          sx={{
            paddingLeft: '8px',
          }}
        >
          <DataGrid
            columns={columns}
            rowsPerPageOptions={[5, 10]}
            disableSelectionOnClick
            disableColumnSelector
            density='compact'
            loading={ props.isLoading }
            rows={ props.data }
            rowCount={ props.pagination.totalRowCount }
            paginationMode="server"
            pagination
            page={ props.pagination.page }
            pageSize={ props.pagination.pageSize }
            onPageChange={ props.handlePageChange }
            onPageSizeChange={ props.handlePageSizeChange }
            components={{
              NoRowsOverlay: () => (
                <div style={ {
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  { props.requested ? 'nenhum cliente encontrado' : '' } 
                </div>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={isMedicalRecordOpen}
        onClose={handleMedicalConsultationClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMedicalConsultationClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={ {
                ml: 2,
                flex: 1,
                fontSize: '1.375rem;',
              } }
              variant="subtitle1"
            >
              Prontuário
            </Typography>
          </Toolbar>
        </AppBar>
        <SessionRecord
          currentEvent={{
            ClientId: {
              _id: clientId,
            },
          }}
          handleClose={handleMedicalConsultationClose}
        />
      </Dialog>
      <Dialog
        fullScreen
        open={isClientDialogOpen}
        onClose={handleCloseClientDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseClientDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, fontSize: '1.375rem;' }}        
              variant="subtitle1">
              Cliente
            </Typography>
          </Toolbar>
        </AppBar>
        <ClientDialog
          handleCloseDialog={ handleCloseClientDialog }
          actionName={ actionName } clientId={ clientId }
          resetPagination={ props.resetPagination }
          search={ props.search } />
      </Dialog>
      <Dialog
        open={isInactivateClientDialogOpen}
        onClose={ handleCloseInactivateClientDialog }
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={ handleCloseInactivateClientDialog }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, fontSize: '1.375rem;' }}        
              variant="subtitle1">
              Alerta
            </Typography>
          </Toolbar>
        </AppBar>
        <InactivateClientDialog
          handleCloseDialog={ handleCloseInactivateClientDialog }
          actionName={ actionName }
          clientId={ clientId }
          resetPagination={ props.resetPagination }
          search={ props.search } />
      </Dialog>
    </>
  );
}

export default React.memo(ClientsDataGrid);