import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

import InputMask from 'react-input-mask';

function InputMaskTextFieldController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    mask,
    defaultHelperTextMessage,
    required,
    disabled,
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <InputMask
          mask={ mask }
          maskChar=" "
          value={ value }
          onChange={ onChange }
          onBlur={ onBlur }
          disabled={disabled}
        >
          {
            (inputProps) =>
              <TextField
                {...inputProps}
                inputRef={ ref }
                label={ label }
                id={ id }
                fullWidth
                variant="outlined"
                helperText={ error?.message || defaultHelperTextMessage }
                error={ invalid }
                required={ required }
                disabled={ disabled }
                style={ {
                  scrollPaddingTop: '10% !important',
                } }
              />
          }
        </InputMask>
      )}
    />
  );
}

export default InputMaskTextFieldController;