import React, { useState, useEffect, useCallback } from 'react';
import { formatISO, toDate, format, isAfter, addHours } from 'date-fns';
import _ from 'lodash';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { isEqual } from 'date-fns';

import DatePickerController from
  '../../components/DatePicker/DatePickerController';

import TimePickerController from
  '../../components/TimePicker/TimePickerController';

import WeekDaysCheckboxController from
  '../../components/Checkbox/WeekDaysCheckboxController';

import RadioController from
  '../../components/Radio/RadioController';

import NumberTextFieldController from
  '../../components/TextField/NumberTextController';

import DropdownController from
  '../../components/Dropdown/DropdownController';

import { enUS } from 'date-fns/locale';

const scheduleService = require('../../service/ScheduleService');

const schema = z
  .object({
    schedule: z.object({
      start: z.date(),
      end: z.date(),
      recurringException: z.array(z.string()).optional(),
      recurring: z.object({
        repeat: z.string(),
        interval: z.number(),
        weekDays: z.string().optional(),
        until: z.string().optional(),
        count: z.number().optional(),
      }).optional(),
    }),
    limit: z.string(),
    options: z.string(),
  });

const defaultValues = {
  schedule: {
    start: new Date(),
    end: new Date(),
    recurringException: null,
    recurring: {
      repeat: 'weekly',
      until: '',
      count: 1,
      interval: 1,
      weekDays: '',
    },
  },
  limit: 'never',
  options: '0',
};

function ReSchedule(props) {

  const [open, setOpen] = useState(false);
  const [isUntilEditable, setUntilEditable] = useState(false);
  const [isCountEditable, setCountEditable] = useState(false);
  const [showWeekDays, setShowWeekDays] = useState(true);
  const [recurringOptions, setRecurringOptions] = useState([
    { id: '0', label: 'Esse evento' },
    { id: '1', label: 'Esse e eventos seguintes' },
    { id: '2', label: 'Todos os eventos' },
  ]);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [isNewRecurring, setIsNewRecurring] = useState(false);
 
  const {
    control,
    getValues,
    setValue,
    resetField,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

    const date = props.schedule.start;
    const weekDay = _.upperCase(format(date, 'EEEEEE', {
      locale: enUS,
    }));
    const values = getValues();

    if (values.schedule.recurring.weekDays === '') {
      setValue('schedule.recurring.weekDays', weekDay);
    }
  };

  const handleEndsWhen = (value) => {
    switch (value) {
    case 'until':
      setUntilEditable(true);
      setCountEditable(false);
      break;
    case 'count':
      setUntilEditable(false);
      setCountEditable(true);
      break;
    default:
      setUntilEditable(false);
      setCountEditable(false);
      break;
    }
  };

  const handleChangeRepeat = (value) => {
    if (value !== 'weekly') {
      setShowWeekDays(false);
    } else {
      setShowWeekDays(true);
    }
  };

  const onSave = useCallback(() => {
    const values = getValues();

    let shouldCreateNewSchedule = false;

    const reschedulePayload = {
      _id: props.schedule._id,
    };

    let newScheduleTemporaryRecurring = null;
    let temporaryRecurring = null;
    let schedule = null;

    if (_.isNil(props.schedule.recurring)) {
      if (isNewRecurring) {
        temporaryRecurring = {};

        temporaryRecurring['repeat'] = values.schedule.recurring.repeat;
        temporaryRecurring['interval'] = values.schedule.recurring.interval;
  
        if (values.limit === 'until') {
          temporaryRecurring['until'] = values.schedule.recurring.until;
        } else if (values.limit === 'count') {
          temporaryRecurring['count'] = values.schedule.recurring.count;
        }
  
        if (values.schedule.recurring.weekDays !== '') {
          temporaryRecurring['weekDays'] = values.schedule.recurring.weekDays;
        }
      }

    } else {
      temporaryRecurring = {};

      temporaryRecurring['repeat'] = values.schedule.recurring.repeat;
      temporaryRecurring['interval'] = values.schedule.recurring.interval;

      if (values.limit === 'until') {
        temporaryRecurring['until'] = values.schedule.recurring.until;
      } else if (values.limit === 'count') {
        temporaryRecurring['count'] = values.schedule.recurring.count;
      }

      if (values.schedule.recurring.weekDays !== '') {
        temporaryRecurring['weekDays'] = values.schedule.recurring.weekDays;
      }

      if (values.options !== '2') {
        shouldCreateNewSchedule = true;
        const date = toDate(values.schedule.start);
        const newDate = format(date, 'yyyy-MM-dd');
        
        if (values.options === '1') {
          newScheduleTemporaryRecurring = _.cloneDeep(temporaryRecurring);
          temporaryRecurring = props.schedule.recurring;

          if (!_.isNil(temporaryRecurring['count'])) {
            delete temporaryRecurring.count;
          }
          temporaryRecurring['until'] = formatISO(date);
        }

        if (_.isNil(values.schedule.recurringException)) {
          reschedulePayload['recurringException'] = [newDate];
        } else {
          reschedulePayload['recurringException'] = values.schedule
            .recurringException;
          reschedulePayload['recurringException'].push(newDate);
        }
      }

    }

    if (!shouldCreateNewSchedule) {
      const startDate = toDate(values.schedule.start);
      const endDate = toDate(values.schedule.end);
      reschedulePayload['start'] = formatISO(startDate);
      reschedulePayload['end'] = formatISO(endDate);
    } else {
      const startDate = toDate(values.schedule.start);
      const endDate = toDate(values.schedule.end);

      const oldSchedule = props.schedule;
      const selectedClient = {
        id: oldSchedule.ClientId,
      };

      schedule = {
        selectedClient: selectedClient,
        medicalConsultationFee: oldSchedule.medicalConsultationFee,
        start: formatISO(startDate),
        end: formatISO(endDate),
        title: oldSchedule.title,
        color: '#' + Math.floor(Math.random() * 16777215).toString(16),
        price: oldSchedule.price,
        sessionsNumber: oldSchedule.sessionsNumber,
      };

      if (values.options === '1') {
        schedule['recurring'] = newScheduleTemporaryRecurring;
      }
    }

    if (!_.isNil(props.schedule.recurring)) {
      reschedulePayload['options'] = values.options;
    }

    if (!_.isNil(temporaryRecurring)) {
      reschedulePayload['recurring'] = temporaryRecurring;
    }

    scheduleService
      .reschedule(reschedulePayload)
      .finally(() => {
        if (shouldCreateNewSchedule) {
          if (!_.isNil(schedule)) {
            scheduleService
              .register(schedule)
              .finally(() => {
                props.handleClose();
              });
          }
        } else {
          props.handleClose();
        }
      });
  }, [getValues, isNewRecurring, props]);

  useEffect(() => {
    const startDate = props.schedule.start;
    const endDate = props.schedule.end;
    const recurring = props.schedule.recurring;

    setValue('schedule.start', startDate);
    setValue('schedule.end', endDate);
    
    if (!_.isNil(recurring)) {
      setValue('schedule.recurring', recurring);
      if (!_.isNil(recurring.until)) {
        setValue('limit', 'until');
        setUntilEditable(true);
      } else if (!_.isNil(recurring.count)) {
        setValue('limit', 'count');
        setCountEditable(true);
      } else {
        setValue('limit', 'never');
      }

      if (!_.isNil(props.schedule.recurringException)) {
        setValue('schedule.recurringException',
          props.schedule.recurringException);
      }
    }

  }, [props, setValue]);

  const handleDoneRecurring = () => {
    setIsNewRecurring(true);
    handleClose();
  };

  const handleChangeDateStart = (value) => {
    const startDate = toDate(value);
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();

    const values = getValues();
    const endDate = toDate(values.schedule.end);
    endDate.setFullYear(year);
    endDate.setMonth(month);
    endDate.setDate(day);

    setValue('schedule.end', endDate);
  };

  const handleChangeTimeStart = (value) => {
    const startDate = toDate(value);
    setValue('schedule.start', startDate);
    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();
    const seconds = startDate.getSeconds();

    const endDate = toDate(startDate);
    endDate.setHours(hours);
    endDate.setMinutes(minutes);
    endDate.setSeconds(seconds);

    const newEndDate = addHours(endDate, 1);

    setValue('schedule.end', newEndDate);
  };

  const handleChangeDateEnd = (value) => {
    const values = getValues();
    const startDate = toDate(values.schedule.start);
    const endDate = toDate(value);
    if (isAfter(endDate, startDate)) {
      setValue('schedule.end', endDate);
    }
  };

  const handeOpenSaveDialog = () => {
    const values = getValues();
    const recurring = values.schedule.recurring;
    const oldRecurring = props.schedule.recurring;

    let untilExists = false;
    let countExists = false;

    if (!_.isNil(oldRecurring)) {
      untilExists = !_.isNil(oldRecurring.until);
      countExists = !_.isNil(oldRecurring.count);
    }
    
    const limitHasChangedWhenNever = values.limit === 'never'
      && (untilExists || countExists);
    
    const limitHasChangedWhenUntil = values.limit === 'until'
      && countExists;
    
    const limitHasChangedWhenCount = values.limit === 'count'
      && untilExists;

    if (!_.isNil(oldRecurring)) {
      if (!_.isEqual(oldRecurring.interval, recurring.interval) ||
      !_.isEqual(oldRecurring.repeat, recurring.repeat) ||
      !_.isEqual(oldRecurring.weekDays, recurring.weekDays) ||
      !_.isEqual(oldRecurring.until, recurring.until) ||
      !_.isEqual(oldRecurring.count, recurring.count)
      ) {
        setRecurringOptions([
          { id: '1', label: 'Esse e eventos seguintes' },
          { id: '2', label: 'Todos os eventos' },
        ]);
        setValue('options', '1');
        setOpenSaveDialog(true);
      } else if (
        !isEqual(values.schedule.start, props.schedule.start) ||
        !isEqual(values.schedule.end, props.schedule.end)
      ) {
        setRecurringOptions([
          { id: '0', label: 'Esse evento' },
          { id: '1', label: 'Esse e eventos seguintes' },
          { id: '2', label: 'Todos os eventos' },
        ]);
        setValue('options', '0');
        setOpenSaveDialog(true);
      } else if (limitHasChangedWhenNever ||
        limitHasChangedWhenUntil ||
        limitHasChangedWhenCount) {
        setRecurringOptions([
          { id: '1', label: 'Esse e eventos seguintes' },
          { id: '2', label: 'Todos os eventos' },
        ]);
        setValue('options', '1');
        setOpenSaveDialog(true);
      } else {
        props.handleClose();
      }
    } else {
      if (!_.isEqual(recurring.interval, 1)     ||
        !_.isEqual(recurring.repeat, 'weekly')  ||
        !_.isEqual(recurring.weekDays, '')      ||
        !_.isEqual(recurring.until, '')         ||
        !_.isEqual(recurring.count, 1)          ||
        !_.isEqual(values.limit, 'never')) {
        setRecurringOptions([]);
        onSave();
      } else if (
        !isEqual(values.schedule.start, props.schedule.start) ||
        !isEqual(values.schedule.end, props.schedule.end)
      ) {
        setRecurringOptions([]);
        onSave();
      } else {
        props.handleClose();
      }
    }
  };

  const handleCloseSave = () => {
    setOpenSaveDialog(false);
  };

  return (
    <Box
      pt={ 5 }
      pl={ 5 }
      pr={ 8.75 }
      sx={ {
        display: 'flex',
        justifyContent: 'center',
      } }>
      <Dialog
        open={ openSaveDialog }
        PaperProps={ {
          elevation: 6,
          sx: {
            minWidth: '384px',
            borderRadius: 2,
          },
        } } >
        <DialogTitle>
          Editar evento {
            !_.isNil(props.schedule.recurring) && 'recorrente'
          } 
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={ 12 }>
              <RadioController
                id='edit-recurring-radio'
                name='options'
                label=''
                control={ control }
                options={ recurringOptions }
                direction='column'
                radioPaddingBottom={ 1 }
                radioPaddingLeft={ 0 }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={ {
              color: 'rgb(0,0,0,0.60)',
              textTransform: 'capitalize',
            } }
            onClick={ handleCloseSave }
          >
            Cancelar
          </Button>
          <Button
            sx={ {
              textTransform: 'capitalize',
            } }
            onClick={ onSave }
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={ open }
        PaperProps={ {
          elevation: 6,
          sx: {
            minWidth: '384px',
            borderRadius: 2,
          },
        } } >
        <DialogTitle>Recorrência</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={ {
                display: 'inline-flex',
                flexDirection: 'row',
                paddingY: 2,
                alignItems: 'center',
              } }>
                <Typography sx={{ minWidth: 100 }}>
                  Repita cada
                </Typography>
                <Box width={ 150 } paddingRight={ 2 } >
                  <NumberTextFieldController
                    id="schedule-count-textfield"
                    name="schedule.recurring.interval"
                    control={ control }
                    disabled={ false }
                    paddingTop={ 0 }
                  />
                </Box>
                <DropdownController
                  id="repeat-textfield"
                  name="schedule.recurring.repeat"
                  label=""
                  control={ control }
                  options={ [
                    {
                      label: 'dia',
                      value: 'daily',
                    },
                    {
                      label: 'semana',
                      value: 'weekly',
                    },
                    {
                      label: 'mês',
                      value: 'monthly',
                    },
                    {
                      label: 'ano',
                      value: 'yearly',
                    },
                  ] }
                  disabled={ false }
                  handleChange={ handleChangeRepeat }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              {
                showWeekDays && (
                  <>
                    <Typography>
                      Repita em
                    </Typography>
                    <WeekDaysCheckboxController
                      name='schedule.recurring.weekDays'
                      control={ control }
                      defaultValue={getValues('schedule.recurring.weekDays')}
                      setValue={setValue}
                    />
                  </>
                )
              }
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: 2 }}>
              <Typography>
                Termina
              </Typography>
              <RadioController
                id='limit-radio'
                name='limit'
                label=''
                control={ control }
                options={ [
                  { id: 'never', label: 'Nunca' },
                  { id: 'until', label: 'Até' },
                  { id: 'count', label: 'Depois' },
                ]}
                direction='column'
                radioPaddingBottom={ 2 }
                radioPaddingLeft={ 0 }
                handleChange={ handleEndsWhen }
              />
            </Grid>
            <Grid item xs={8}>
              <Box sx={ {
                display: 'inline-flex',
                flexDirection: 'column',
                paddingTop: 11.75,
              }}>
                <DatePickerController
                  id="schedule-until-datepicker"
                  name="schedule.recurring.until"
                  label="Data de Término"
                  control={ control }
                  disabled={ !isUntilEditable }
                />
                <Box width={ 175 }>
                  <NumberTextFieldController
                    id="schedule-count-textfield"
                    name="schedule.recurring.count"
                    control={ control }
                    disabled={ !isCountEditable }
                    paddingTop={ 1 }
                    hasAdorment={ true }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={ {
              color: 'rgb(0,0,0,0.60)',
              textTransform: 'capitalize',
            } }
            onClick={ () => {
              if (!_.isNil(props.schedule.recurring)) {
                setValue('schedule.recurring', props.schedule.recurring);
              } else {
                setValue('schedule.recurring', {
                  repeat: 'weekly',
                  until: '',
                  count: 1,
                  interval: 1,
                  weekDays: '',
                });
              }
              resetField('limit');
              handleClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            sx={ {
              textTransform: 'capitalize',
            } }
            onClick={ handleDoneRecurring }
          >
            Feito
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        paddingTop={ 4 }
        justifyContent="end"
        spacing={ 0 }
        rowSpacing={ 1 }
        columnSpacing={ 1 }
        maxWidth='700px'
      >
        <Grid item md={3} sm={ 6 } xs={ 12 }>
          <DatePickerController
            id="schedule-startDate-datepicker"
            name="schedule.start"
            label="Data Inicial"
            control={ control }
            disabled={ false }
            onChangeDate={ handleChangeDateStart }
          />
        </Grid>
        <Grid item md={2} sm={ 6 } xs={ 12 }>
          <TimePickerController
            id="schedule-endDate-timepicker"
            name="schedule.start"
            label="Hora Inicial"
            control={ control }
            disabled={ false }
            onChangeDate={ handleChangeTimeStart }
          />
        </Grid>
        <Grid item md={1} sm={ 12 } xs={12}>
          <Typography sx={ {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}>
            para
          </Typography>
        </Grid>
        <Grid item md={2} sm={ 6 } xs={ 12 }>
          <TimePickerController
            id="schedule-startDate-datepicker"
            name="schedule.end"
            label="Hora Final"
            control={ control }
            disabled={ false }
            onChangeDate={ handleChangeDateEnd }
          />
        </Grid>
        <Grid item md={3} sm={ 6 } xs={ 12 }>
          <DatePickerController
            id="schedule-endDate-timepicker"
            name="schedule.end"
            label="Data Final"
            control={ control }
            disabled={ true }
          />
        </Grid>
        <Grid item xs={ 12 } mt={ 4 }>
          <Box sx={ {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
          } }>
            <Button
              id="recurring-button"
              size="medium"
              variant="contained"
              disableElevation={true}
              disabled={ false }
              sx={ { marginRight: '8px' } }
              onClick={ handleOpen }
            >
              <Typography
                textTransform="capitalize"
                fontSize="0.85rem"
                fontWeight={ 600 }>
                { _.isNil(props.schedule.recurring) && 'Criar' } Recorrência
              </Typography>
            </Button>
            <Button
              id="save-button"
              size="medium"
              variant="contained"
              disableElevation={true}
              disabled={ false }
              sx={ { 
                marginRight: 0,
              } }
              onClick={ handeOpenSaveDialog }
            >
              <Typography
                textTransform="capitalize"
                fontSize="0.85rem"
                fontWeight={ 600 }>
                Salvar
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReSchedule;