/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreateIcon from '@mui/icons-material/Create';

import { parseISO, format } from 'date-fns';
import _ from 'lodash';

import ScheduleStatus from '../../../utils/ScheduleStatus';

const RenderStatusButton = (props) => {
  const [status, setStatus] = useState(props.status);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    if (value !== null) {
      setStatus(value);
      props.handleEditScheduleStatus(props.id, value, props.date);
    }
    setAnchorEl(null);
  };

  const getIconByStatus = useCallback(() => {
    switch (status) {
    case ScheduleStatus.confirmed:
      return (
        <ThumbUpIcon fontSize='medium' />
      );
    case ScheduleStatus.accomplished:
      return (
        <DoneIcon fontSize='medium' />
      );
    case ScheduleStatus.canceled:
      return (
        <NotInterestedIcon fontSize='medium' />
      );
    case ScheduleStatus.absent:
      return (
        <ThumbDownIcon fontSize='medium' />
      );
    default:
      return (
        <MoreHorizIcon fontSize='medium' />
      );
    }
  }, [status]);

  return (
    <Box>
      <IconButton
        id="status-menu-button"
        size="small"
        aria-label='status'
        aria-controls={ open ? 'status-menu' : undefined }
        aria-haspopup="true"
        aria-expanded={ open ? 'true' : undefined}
        onClick={handleClick}
        disabled={false}
      >
        {getIconByStatus()}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
          <MenuItem
            onClick={ () => handleClose(ScheduleStatus.confirmed) }
          >
            <ListItemIcon>
              <ThumbUpIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Confirmou agendamento</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={ () => handleClose(ScheduleStatus.accomplished) }
          >
            <ListItemIcon>
              <DoneIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Realizada</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={ () => handleClose(ScheduleStatus.canceled) }
          >
            <ListItemIcon>
              <NotInterestedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Cancelada</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={ () => handleClose(ScheduleStatus.absent) }
          >
            <ListItemIcon>
              <ThumbDownIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Ausente</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={ () => handleClose(ScheduleStatus.noStatus) }
          >
            <ListItemIcon>
              <MoreHorizIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Sem status</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const RenderActionButtons = (props) => {

  const onHandleCalendarButton = () => {
    // console.log('open calendar');
  };

  const onHandleEditButton = () => {
    props.fetchSessionRecords(props.id, props.date);
  };

  return (
    <Box>
      <IconButton
        id="actions-calendar-button"
        size="small"
        aria-label='actions-calendar'
        onClick={onHandleCalendarButton}
        disabled={true}
      >
        <CalendarMonthIcon fontSize='medium' />
      </IconButton>
      <IconButton
        id="actions-edit-button"
        size="small"
        aria-label='actions-edit'
        onClick={onHandleEditButton}
        disabled={false}
      >
        <CreateIcon fontSize='medium' />
      </IconButton>
    </Box>
  );
};

const columns = [
  {
    id: 'session',
    label: 'Sessão',
    width: 120,
    align: 'center',
    format: (value) => {
      const fulldate = format(value, 'P');
      const hour = format(value, 'HH');
      const minutes = format(value, 'mm');
      return fulldate + '\n' + hour + 'h' + minutes;
    },
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'center',
    renderCell: RenderStatusButton,
  },
];

function createRowData(session, status, id) {
  return { session, status, id };
}

function SessionsCustomTable(props) {

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!_.isNil(props.schedules)) {
      const list = [];
      props.schedules.forEach((schedule) => {
        const item = createRowData(
          schedule.start,
          schedule.status,
          schedule._id);
        list.push(item);
      });
      setRows(list);
    }
  }, [props]);

  return (
    <Box sx={ {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '324px',
      paddingBottom: 2,
    }}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 390 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography style={{ fontSize: '1.2rem' }} component='p'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={ -1 }
                      key={ index }
                    >
                      { columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === 'status') {
                          const RenderCell = column.renderCell;
                          return (
                            <TableCell
                              key={ column.id + '-' + index }
                              align={ column.align }
                            >
                              <RenderCell
                                id = { row.id }
                                date= { row.session }
                                status={ row.status }
                                handleEditScheduleStatus={
                                  props.handleEditScheduleStatus
                                }
                              />
                            </TableCell>
                          );
                        } else if (column.id === 'session') {
                          return (
                            <TableCell
                              key={ column.id + '-' + index }
                              align={ column.align }
                              sx={ { width: column.width } }
                            >
                              <Typography
                                component='p'
                              >
                                {column.format && column.id === 'session'
                                  ? column.format(value)
                                  : value }
                              </Typography>
                            </TableCell>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </TableRow>
                  );
                }) }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default SessionsCustomTable;