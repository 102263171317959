import React, { useState, useEffect, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import Link from '@mui/material/Link';
import { Link as LinkRouter } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

const userService = require('../../service/UserService');

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Todos os direitos reservados © '}
      <Link color="inherit" href="https://www.cafecomjava.com.br/">
        Café com Java
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [email, setEmail] = useState('');
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAuth();

  const handleToggle = useCallback(() => {
    setOpenBackDrop(!openBackDrop);
  }, [openBackDrop]);

  const doLogin = useCallback(() => {
    setOpenErrorMessage(false);
    handleToggle();
    userService
      .doLogin(email, password)
      .then(response => {
        login(response.data.token, rememberMe);
        handleClose();
      })
      .catch(e => {
        setErrorMessage(
          e.response.data.status === 'user not found'
            ? 'Usuário não encontrado'
            : e.response.data.status,
        );
        setOpenErrorMessage(true);
        handleClose();
      });
    // .finally(console.log("implementar o finally"));
  }, [handleToggle, email, password, login, rememberMe]);

  const handleSubmit = useCallback(() => {
    doLogin();
  }, [doLogin]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleSubmit]);

  const handleClose = () => {
    setOpenBackDrop(false);
  };

  function handleEmailChange(e) {
    setEmail(e.target.value);
    // console.log(email);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Logar
          </Typography>
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            style={{ display: openErrorMessage ? '' : 'none' }}
          >
            <Alert severity="error">
              {errorMessage}
            </Alert>
          </Stack>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleEmailChange}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordChange}
            />
            <FormControlLabel
              checked={ rememberMe }
              onChange={ (event) => {
                setRememberMe(event.target.checked);
              }}
              control={<Checkbox color="primary" />}
              label="Lembrar login"
            />
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            />
            <Button
              onClick={doLogin}
              fullWidth
              variant="contained"
              // href="/Schedule"
              sx={{ mt: 3, mb: 2 }}
            >
              {' '}Login
            </Button>
            <Grid container>
              <Grid item xs>
                <LinkRouter style={{ fontSize: '14px' }} to='/forgotPassword'>
                  Esqueceu sua senha?
                </LinkRouter>
              </Grid>
              <Grid item>
                <Link variant="body2">
                  {'Não tem um conta? Se inscreva!'}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
