import _ from 'lodash';
import getAge from './getAge';

function validateClientFields(client) {

  const validation = {
    failedClient: false,
    needsFinancialResponsible: false,
  };
    
  if (!_.isNil(client.birthDate) || !_.isEmpty(client.birthDate)) {
    const age = getAge(client.birthDate);
    if (age < 18) {
      validation.needsFinancialResponsible = true;
      validation.failedClient = false;
    }
  }

  return validation;
}

export default validateClientFields;