import React from 'react';

import {
  Grid, Typography, Divider, 
} from '@mui/material';

import {
  TextFieldController, InputMaskTextFieldController,
} from '../../../components/TextField';

function ClientAddress({ control, isViewMode }) {
  return (
    <>
      <Grid item xs={ 12 }
        sx={ {
          paddingTop: '20px !important',
          paddingBottom: 0,
        } }>
        <Typography variant="h6" fontWeight={500}>
          Endereço e Contatos
        </Typography>
      </Grid>
      <Grid item xs={ 12 }
        sx={ {
          paddingBottom: 5,
        } }>
        <Divider
          sx={ { height: 1 } }
          orientation="horizontal"
          flexItem
        />
      </Grid>
      <Grid item sm={ 4 } xs={ 12 }>
        <InputMaskTextFieldController
          id="client-cellphone-textfield"
          name="client.cellphone"
          label="Telefone"
          mask={'(99)9999-99999'}
          control={ control }
          defaultHelperTextMessage=""
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 8 } xs={ 12 }>
        <TextFieldController
          id="client-email-textfield"
          name="client.email"
          label="E-mail"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 4 } xs={ 12 }>
        <TextFieldController
          id="address-cep-textfield"
          name="address.cep"
          label="CEP"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 4 } xs={ 12 }>
        <TextFieldController
          id="address-state-textfield"
          name="address.state"
          label="Estado"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 4 } xs={ 12 }>
        <TextFieldController
          id="address-city-textfield"
          name="address.city"
          label="Cidade"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 4 } xs={ 12 }>
        <TextFieldController
          id="address-neighbourhood-textfield"
          name="address.neighbourhood"
          label="Bairro"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 8 } xs={ 12 }>
        <TextFieldController
          id="address-street-textfield"
          name="address.street"
          label="Rua"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 4 } xs={ 12 }>
        <TextFieldController
          id="address-number-textfield"
          name="address.number"
          label="Número"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={8} xs={12} style={{ paddingBottom: 30 }}>
        <TextFieldController
          id="address-complement-textfield"
          name="address.complement"
          label="Complemento"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
    </>
  );
}

export default ClientAddress;