import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import React from 'react';
import _ from 'lodash';

function DatePickerController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    disabled,
    max,
    variant = 'outlined',
    onChangeDate,
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <DatePicker
          openTo="year"
          views={ ['year', 'month', 'day'] }
          inputFormat="dd/MM/yyyy"
          maxDate={max}
          disabled={ disabled }
          id={ id }
          label={ label }
          value={ value }
          onChange={ (value) => {
            if (!_.isNil(onChangeDate)) {
              onChangeDate(value);
            }
            onChange(value);
          } }
          onBlur={ onBlur }
          inputRef={ ref }
          sx={ { width: '100%' } }
          renderInput={ params => (
            <TextField
              { ...params }
              fullWidth
              error={ invalid }
              helperText={ error?.message || '' }
              variant={ variant }
            />
          ) }
        />
      )}
    />
  );
}

export default DatePickerController;