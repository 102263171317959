import React from 'react';

import {
  Grid, Typography, Divider,
} from '@mui/material';

import _ from 'lodash';

import {
  TextFieldController,
  InputMaskTextFieldController,
} from '../../../components/TextField';

import DropdownController from
  '../../../components/Dropdown/DropdownController';

import DatePickerController from
  '../../../components/DatePicker/DatePickerController';

import AutocompleteController from
  '../../../components/Autocomplete/AutocompleteController';

import getCountries from '../../../utils/getCountries';
import getGenders from '../../../utils/getGenders';
import getSchooling from '../../../utils/getSchooling';
import getMaritalStates from '../../../utils/getMaritalStates';

const genders = getGenders();
genders.shift();

const countries = getCountries();
const schoolings = getSchooling();
const maritalStates = getMaritalStates();

const allCountries = countries.map((country) => {
  return {
    id: country.isocode,
    label: country.namePtBR,
  };
});

const sortedCountries = _.sortBy(
  allCountries, item => item.label.toLowerCase(),
);

function ClientFields({ control, isViewMode }) {
  return (
    <>
      <Grid item xs={ 12 }
        sx={ {
          paddingTop: '20px !important',
          paddingBottom: 0,
        } }>
        <Typography variant="h6" fontWeight={500}>
          Dados Pessoais
        </Typography>
      </Grid>
      <Grid item xs={ 12 }
        sx={ {
          paddingBottom: 5,
        } }>
        <Divider
          sx={ { height: 1 } }
          orientation="horizontal"
          flexItem
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <TextFieldController
          id="client-name-textfield"
          name="client.name"
          label="Nome *"
          defaultHelperTextMessage="Obrigatório"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFieldController
          id="client-surname-textfield"
          name="client.surname"
          label="Sobrenome *"
          defaultHelperTextMessage="Obrigatório"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <TextFieldController
          id="client-profession-textfield"
          name="client.profession"
          label="Profissão"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <DropdownController
          id="client-schooling-textfield"
          name="client.schooling"
          label="Escolaridade"
          control={ control }
          options={ schoolings }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <DropdownController
          id="client-gender-textfield"
          name="client.gender"
          label="Sexo"
          control={ control }
          options={ genders }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <DropdownController
          id="client-maritalState-textfield"
          name="client.maritalState"
          label="Estado Civil"
          control={ control }
          options={ maritalStates }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <InputMaskTextFieldController
          id="client-cpf-textfield"
          name="client.cpf"
          label="CPF *"
          mask={'999.999.999-99'}
          control={ control }
          defaultHelperTextMessage="Obrigatório"
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <TextFieldController
          id="client-rg-textfield"
          name="client.rg"
          label="RG"
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <AutocompleteController
          id="client-naturalness-textfield"
          name="client.naturalness"
          label="Natural de"
          control={ control }
          options={ sortedCountries }
          disabled={isViewMode}
        />
      </Grid>
      <Grid item sm={ 6 } xs={ 12 }>
        <DatePickerController
          id="client-birthDate-textfield"
          name="client.birthDate"
          label="Data de Nascimento"
          max={new Date()}
          control={ control }
          disabled={isViewMode}
        />
      </Grid>
    </>
  );
}

export default ClientFields;