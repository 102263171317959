const getMaritalStates = () => [
  {
    label: 'Solteiro',
    value: 'single',
  },
  {
    label: 'Casado',
    value: 'married',
  },
  {
    label: 'Separado',
    value: 'separated',
  },
  {
    label: 'Divorciado',
    value: 'divorced',
  },
  {
    label: 'Viúvo',
    value: 'widower',
  },
  {
    label: 'Amasiado',
    value: 'mated',
  },
];

export default getMaritalStates;