import { SEVERITY_ERROR } from '../../../utils/MiscellaneousUtils';
import _ from 'lodash';
import getAge from './getAge';

function validateResponsibleFields(
  index,
  responsible,
  showMessage,
  setFocus,
  setError,
) {
  let failed = false;

  if (!_.isNil(responsible.birthDate) ||
    !_.isEmpty(responsible.birthDate)) {
    const age = getAge(responsible.birthDate);
    if (age < 18) {
      failed = true;
      showMessage(
        'Responsável precisa ser maior.',
        SEVERITY_ERROR,
      );
      setError(`responsibles.${ index }.birthDate`, {
        type: 'custom', message: 'Responsável precisa ser maior.',
      });
      setFocus(`responsibles.${ index }.birthDate`);
    }
  }

  return failed;
}

export default validateResponsibleFields;