import { TextField, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

function TextFieldController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    defaultHelperTextMessage,
    required,
    disabled,
    isNumber,
    type = 'text',
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <TextField
          value={ value }
          onChange={ (event) => {
            if (isNumber) {
              const value = event.target.value.replace(/\D/g, '');
              onChange(value);
            } else {
              onChange(event);
            }
          } }
          onBlur={ onBlur }
          inputRef={ ref }
          label={ label }
          id={ id }
          fullWidth
          variant="outlined"
          helperText={ error?.message || defaultHelperTextMessage }
          error={ invalid }
          required={ required }
          disabled={ disabled }
          type={type}
          InputProps={
            isNumber ?
              {
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              } 
              :
              undefined
          }
        />
      )}
    />
  );
}

export default TextFieldController;