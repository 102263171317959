import axios from 'axios';
const URL = process.env.REACT_APP_API_URL;

export async function doLogin(email, password) {
  return axios.get(`${URL}v1/user/login`, {
    params: {
      email: email,
      password: password,
    },
  });
}

export async function recoverPassword(email) {
  return axios.post(`${URL}v1/user/recoverPassword`, { email: email });
}

export async function resetPassword(token, email, password) {
  return axios.patch(`${ URL }v1/user/resetPassword`, {
    token: token,
    email: email,
    password: password,
  });
}

export async function verifyRecoverToken(token) {
  return axios.post(`${ URL }v1/user/verifyRecoverToken`, {
    token: token,
  });
}