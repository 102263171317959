/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Divider,
} from '@mui/material';

import { parseISO, format } from 'date-fns';

import {
  EditTextFieldController,
} from '../../../components/TextField';

function SessionRecordsListController({
  control,
  list,
  handleSavePostField,
  handleResetPostField,
}) {

  const formatPostDate = (strDate) => {
    const date = parseISO(strDate);
    const fulldate = format(date, 'P');
    const hour = format(date, 'HH');
    const minutes = format(date, 'mm');
    return fulldate + '\n' + hour + 'h' + minutes;
  };

  return (
    <Box sx={ {
      width: '100%',
      minWidth: 300,
      paddingBottom: 10,
    } }
    >
      <List sx={ { width: 'calc(100% - 48px)' } }>
        {
          list.map((item, index) => {
            const postId = item._id;
            const isNewSessionRecord = item.description === ' ' &&
              item.evolution === ' ';

            return (
              <React.Fragment key={index}>
                <ListItem
                  alignItems="flex-start"
                  sx={ {
                    border: 'solid',
                    borderWidth: 'thin',
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                  } }>
                  <ListItemAvatar sx={ { width: 100 } }>
                    <Typography align='center'>
                      { formatPostDate(item.start) }
                    </Typography>
                  </ListItemAvatar>
                  <Box sx={ {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                  } }>
                    <ListItemText
                      sx={ { pl: 2 } }
                      primary={ 
                        <Typography fontSize="1.15rem" pb={2}>
                          Descrição
                        </Typography>
                      }
                      secondaryTypographyProps={{ component: 'div' }}
                      secondary={
                        <EditTextFieldController
                          control={ control }
                          id={
                            `sessionRecords-${ index }-description-textfield`
                          }
                          name={
                            `sessionRecords[${ index }].description`
                          }
                          label="Descrição"
                          saveField={ (value) => {
                            handleSavePostField(
                              postId,
                              index,
                              'description',
                              value,
                            );
                          } }
                          resetField={ () => {
                            handleResetPostField(index, 'description');
                          } }
                          isNewSessionRecord={isNewSessionRecord}
                        />
                      }
                    />
                    <ListItemText
                      sx={ { pl: 2 } }
                      primary={
                        <Typography fontSize="1.15rem" pt={2} pb={2}>
                          Evolução
                        </Typography>
                      }
                      secondaryTypographyProps={{ component: 'div' }}
                      secondary={
                        <EditTextFieldController
                          control={ control }
                          id={
                            `sessionRecords-${ index }-evolution-textfield`
                          }
                          name={
                            `sessionRecords[${ index }].evolution`
                          }
                          label="Evolução"
                          saveField={ (value) => {
                            handleSavePostField(
                              postId,
                              index,
                              'evolution',
                              value,
                            );
                          } }
                          resetField={ () => {
                            handleResetPostField(index, 'evolution');
                          } }
                          isNewSessionRecord={isNewSessionRecord}
                        />
                      }
                    />
                  </Box>
                </ListItem>
                {
                  (list.length > 1) && (index !== list.length - 1)
                    ?
                    <Divider
                      sx={ { height: 64, mr: 8 } }
                      orientation="vertical"
                      flexItem
                    />
                    : 
                    null
                }
              </React.Fragment>
            );
          })
        }
      </List>
    </Box>
  );
}

export default SessionRecordsListController;