import {
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

import _ from 'lodash';
import '../../assets/css/Checkbox.css';

function CheckboxController(props) {
  
  const {
    name,
    control,
    label,
    rules,
    id,
    disabled,
    resetCheckboxes,
  } = props;

  return (
    <Controller
      name={ name }
      control={ control }
      rules={rules || undefined}
      render={ ({
        field: { value, onChange, onBlur, ref },
      }) => (
        <>
          <FormControlLabel
            id={ id }
            checked={ value || false }
            control={
              <Checkbox />
            }
            disabled={ disabled }
            onChange={ (event) => {
              if (!_.isNil(resetCheckboxes)) {
                resetCheckboxes();
              }
              onChange(event.target.checked);
            }}
            onBlur={ onBlur }
            inputRef={ ref }
            label={ label }
            className='checkbox-reverse'
          />
        </>
      )}
    />
  );
}

export default CheckboxController;