import { createContext, useContext, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import Cookies from 'universal-cookie';
// eslint-disable-next-line no-unused-vars
import { addHours, addMinutes, addDays } from 'date-fns';
const AuthContext = createContext();
const cookies = new Cookies();

export const AuthProvider = ({ children }) => {
  const defaultToken = window.localStorage.getItem('token');
  const [auth, setAuth] = useLocalStorage('token', defaultToken);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = useCallback(async (data, rememberMe = false) => {
    if (!rememberMe) {
      const now = new Date();
      const limit = addDays(now, 15);
      
      cookies.set('token', data, {
        expires: limit,
      });

      window.location = '/calendar';
    } else {
      setAuth(data);
      navigate('/calendar');
    }
  }, [navigate, setAuth]);

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setAuth(null);
    cookies.remove('token');
    navigate('/', { replace: true });
  }, [navigate, setAuth]);

  const value = useMemo(
    () => ({
      auth,
      login,
      logout,
    }),
    [login, logout, auth],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};