import _ from 'lodash';

function isCPFNumber(strCPF) {
  const cpf = removeNonNumber(strCPF);

  if (_.isEmpty(cpf)              ||
    cpf.length !== 11             ||
    _.isEqual(cpf, '00000000000') ||
		_.isEqual(cpf, '11111111111') || 
		_.isEqual(cpf, '22222222222') || 
		_.isEqual(cpf, '33333333333') || 
		_.isEqual(cpf, '44444444444') || 
		_.isEqual(cpf, '55555555555') || 
		_.isEqual(cpf, '66666666666') || 
		_.isEqual(cpf, '77777777777') || 
		_.isEqual(cpf, '88888888888') || 
		_.isEqual(cpf, '99999999999')) {
    return false;
  }

  let sum; let rev;

  // Verify First Digit
  sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  rev = (sum * 10) % 11;

  if ((rev === 10) || (rev === 11)) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(9))) {
    return false;
  }

  // Verify Second Digit
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  rev = (sum * 10) % 11;

  if ((rev === 10) || (rev === 11)) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(10))) {
    return false;
  }

  return true;
}

function removeNonNumber(str) {
  return str.replace(/[^\d]+/g, '');
}

export default isCPFNumber;